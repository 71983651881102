import React, { useEffect, useState } from "react";
import UploadBanner from "../../components/banners/UploadBanner";
import { Row, Col, Input, Button } from "reactstrap";
import cx from "classnames";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import * as userAction from "../../actions/user-action-type";
import { withRouter, useParams } from "react-router-dom";
import globalConfig from "../../globalConfig";
import ArrowIcon from "../../assets/images/icons/left-arrow.svg";
import moment from "moment";
import "./style.scss";
import StoreMenu from "../../components/layout/header/store-menu";
import cartB from "../../assets/images/cart-banner-lq.jpg";

const EcomOrderDetails = (props) => {
  const params = useParams();
  const { hosCardsOrderHistoryData, getHosCardsOrderHistory } = props;
  const [hosCardsHistory, setHosCardsHistory] = useState();
  const history = useHistory();
  const isOrders = window.location.pathname.includes("orders");

  useEffect(() => {
    globalConfig.isStorePage = true;
    window.scrollTo({ behavior: "smooth", top: 0 });
    const data = new FormData();
    data.append("action", "listeComOrders");
    getHosCardsOrderHistory(data);
  }, []);

  useEffect(() => {
    console.log(hosCardsOrderHistoryData);
    const selectedHosCard = hosCardsOrderHistoryData.find(
      (hosCard) => hosCard.order_number === params.order_number
    );
    console.log(selectedHosCard);
    setHosCardsHistory(selectedHosCard);
  }, [hosCardsOrderHistoryData]);

  return (
    <div>
      <StoreMenu />
      <UploadBanner
        backgroundImage={!isOrders && cartB}
        linkPage={[
          { type: "link", name: "Home", link: "/" },
          { name: "Ecom Order Details" },
        ]}
      />
      <section className="uploadSection">
        <div className="UploadCard">
          <Row>
            <div style={{ textAlign: "center", fontSize: 20 }}>
              {hosCardsHistory && (
                <div style={{ marginBottom: "2rem" }}>
                  <div className="success-checkmark">
                    <div className="check-icon">
                      <span className="icon-line line-tip"></span>
                      <span className="icon-line line-long"></span>
                      <div className="icon-circle"></div>
                      <div className="icon-fix"></div>
                    </div>
                  </div>

                  <span className="cake-card-content">
                    <div
                      className="cake-card-heading-text"
                      style={{ marginTop: "1rem" }}
                    >
                      Order Number :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {hosCardsHistory.order_number}
                      </span>
                    </div>
                    <div className="cake-card-heading-text">
                      Order Date :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {moment(hosCardsHistory.order_date).format(
                          "DD/MM/YYYY"
                        )}{" "}
                        {hosCardsHistory.order_date.split(" ")[1]}
                      </span>
                    </div>
                    <div className="cake-card-heading-text">
                      Status :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {hosCardsHistory.status == 0
                          ? "Pending"
                          : hosCardsHistory.status == 1
                          ? hosCardsHistory.processing_status
                            ? `${hosCardsHistory.processing_status}`
                            : ""
                          : "Failed"}
                      </span>
                    </div>
                    {hosCardsHistory.items.length > 0 && (
                      <div className="cake-card-heading-text">
                        Items :{" "}
                        {hosCardsHistory.items.map((item, index) => (
                          <span
                            style={{
                              fontSize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            {item.title} | qty : {item.qty} |{" "}
                            {hosCardsHistory.total} AED{" "}
                            {index !== hosCardsHistory.items.length - 1 && ","}
                          </span>
                        ))}
                      </div>
                    )}
                  </span>
                </div>
              )}
            </div>
          </Row>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = ({ user: { hosCardsOrderHistoryData } }) => ({
  hosCardsOrderHistoryData,
});

const mapDispatchToProps = {
  getHosCardsOrderHistory: userAction.getHosCardsOrderHistory,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EcomOrderDetails));
