import React, { useEffect, useState } from "react";
import UploadBanner from "../../components/banners/UploadBanner";
import { Row, Col, Input, Button } from "reactstrap";
import cx from "classnames";
import axios from "axios";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import * as userAction from "../../actions/user-action-type";
import { withRouter } from "react-router-dom";
import { getLanguage, setLanguage, useTranslation } from "react-multi-lang";
import random1 from "../../assets/images/random/About-us.jpeg";
import random2 from "../../assets/images/random/Bags.jpeg";
import random3 from "../../assets/images/random/collections.jpeg";
import random4 from "../../assets/images/random/collections-option-3.jpg";
import random5 from "../../assets/images/random/Gshar-Products34.jpg";
import random6 from "../../assets/images/random/Homepage1.jpeg";
import random7 from "../../assets/images/random/Homepage2.jpeg";
import moment from "moment";
import globalConfig from "../../globalConfig";
import ContentLoader from "react-content-loader";
import cartB from "../../assets/images/cart-banner-lq.jpg";
import StoreMenu from "../../components/layout/header/store-menu";

const Orders = (props) => {
  //   const { orderData, getOrders } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  const headers = {
    Authorization: "Bearer " + localStorage.getItem("jwt"),
  };
  localStorage.setItem("pathname", history.location.pathname);

  const images = [
    random1,
    random2,
    random3,
    random4,
    random5,
    random6,
    random7,
  ];
  useEffect(() => {
    globalConfig.isStorePage = true;
    setLoading(true);
    window.scrollTo({ behavior: "smooth", top: 0 });

    const data = new FormData();
    data.append("action", "listeComOrders");
    axios
      .post(process.env.REACT_APP_BASE_URL, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res.data.data);
        if (res?.data?.data)
          setOrders(
            res.data.data.sort((a, b) =>
              moment(b.order_date, "YYYY-MM-DD HH:mm:ss").diff(
                moment(a.order_date, "YYYY-MM-DD HH:mm:ss")
              )
            )
          );
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <StoreMenu />
      <UploadBanner
        backgroundImage={cartB}
        linkPage={[
          { type: "link", name: "Home", link: "/" },
          { name: "Orders" },
        ]}
      />
      <section className="uploadSection">
        <div className="UploadCard">
          <Row>
            <Col md={3}>{/* <UploadContent /> */}</Col>
            <Col md={6}>
              <div className="destinations-package">
                <div className="">
                  <div className="backContainer d-flex">
                    <p className="menu-text">
                      <i
                        style={{ cursor: "pointer" }}
                        className="fas fa-arrow-left arrow"
                        onClick={() => history.push("/orders-settings")}
                      ></i>
                      Order History
                    </p>

                    {/* <img src={ArrowIcon} height="20px" alt="Back icon" onClick={() => history.push({ pathname: "/settings" })} />
                    <div className="password_text">{t('settings.order_history')}</div> */}
                  </div>
                  {loading ? (
                    <Row>
                      {["", "", ""].map((data, index) => (
                        <Col md={12}>
                          <ContentLoader
                            speed={2}
                            // width={638}
                            // height={420}
                            viewBox="0 0 500 150"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                            {...props}
                          >
                            <rect
                              x="1"
                              y="0"
                              rx="30"
                              ry="30"
                              width="500"
                              height="130"
                            />
                          </ContentLoader>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <>
                      {orders.length == 0 ? (
                        <Col
                          className="mb-3"
                          style={{
                            textAlign: "center",
                            marginTop: "100px",
                            justifyContent: "center",
                          }}
                        >
                          <p style={{ textAlign: "center", fontWeight: "700" }}>
                            {/* {t("order_history.empty_message")} */}
                            No orders on your account
                          </p>
                        </Col>
                      ) : (
                        orders.map((item, index) => (
                          <div key={index}>
                            <div className="cake-card-wrapper">
                              <div
                                className="cake-card-body ticket"
                                style={{
                                  height: item.status == 1 ? "160px" : "130px",
                                }}
                                onClick={() =>
                                  item.status == 1 &&
                                  history.push(
                                    `/stores/ecom-order-details/${item.order_number}`
                                  )
                                }
                              >
                                {/* <span className="cake-card-left-img">
                              <img
                                src={
                                  "https://api.discovershurooq.ae/files/" +
                                  item.package_img
                                }
                                alt=""
                              />
                            </span> */}
                                <span className="cake-card-content">
                                  <div
                                    className="cake-card-heading-text"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    {item.order_number}
                                  </div>
                                  <div
                                    className="cake-card-heading-text"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    {moment(item.order_date).format(
                                      "DD/MM/YYYY"
                                    )}{" "}
                                    {item.order_date.split(" ")[1]}
                                  </div>
                                  {/* <span className="cake-card-heading-text">
                                {isEnglish == "en" ? item.name : item.name}
                              </span> */}
                                  {item.status == 1 && (
                                    <div
                                      className="cake-card-heading-text"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      Status :{" "}
                                      {item.fullfillment_status[0].status_name}
                                    </div>
                                  )}
                                  <div
                                    className="cake-card-heading-text"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    AED : {item.total}
                                  </div>
                                  {item.items && (
                                    <div
                                      className="cake-card-heading-text"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      Items : {item.items.length}
                                    </div>
                                  )}
                                </span>

                                <span
                                  className="cake-card-right"
                                  style={{
                                    backgroundColor:
                                      item.status == 0
                                        ? "gray"
                                        : item.status == 1
                                        ? "green"
                                        : "red",
                                    borderRadius: "0px 12px 12px 0px",
                                    padding: "9px",
                                  }}
                                >
                                  {item.status == 0
                                    ? "Pending"
                                    : item.status == 1
                                    ? item.processing_status
                                      ? `${item.processing_status}`
                                      : ""
                                    : "Failed"}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </>
                  )}
                </div>
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = ({ user: { orderData } }) => ({
  orderData,
});

const mapDispatchToProps = {
  getOrders: userAction.getOrders,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orders));
