import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import RegisterBanner from "../../components/banners/RegisterBanner";
import StoreMenu from "../../components/layout/header/store-menu";
import OwlCarousel from "react-owl-carousel2";
import PhoneInput from "react-phone-input-2";
import { Row, Col, Card, CardBody, Modal, Input, Spinner } from "reactstrap";
import trash from "../../assets/images/icons/trash.svg";
import coupon from "../../assets/images/discount.png";
import cartbanner from "../../assets/images/cartbanner.jfif";
import axios from "axios";
import { Radio } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import "./style.scss";
import globalConfig from "../../globalConfig";
import CartBanner from "../../components/banners/CartBanner";
import random1 from "../../assets/images/random/About-us.jpeg";
import random2 from "../../assets/images/random/Bags.jpeg";
import random3 from "../../assets/images/random/collections.jpeg";
import random4 from "../../assets/images/random/collections-option-3.jpg";
import random5 from "../../assets/images/random/Gshar-Products34.jpg";
import random6 from "../../assets/images/random/Homepage1.jpeg";
import random7 from "../../assets/images/random/Homepage2.jpeg";

export default function CheckOut() {
  const [address, setAddress] = useState([]);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [taxPrice, setTaxPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState();
  const [btnLoading, setBtnLoading] = useState(false);
  const [addressInput, setAddressInput] = useState({
    name: "",
    city: "",
    state: "Abu Dhabi",
    country: "United Arab Emirates",
    landmark: "",
    address: "",
    phone: "",
  });
  const [modal, setModal] = useState(false);
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("jwt"),
  };
  const history = useHistory();
  localStorage.setItem("pathname", history.location.pathname);

  const images = [
    random1,
    random2,
    random3,
    random4,
    random5,
    random6,
    random7,
  ];

  useEffect(() => {
    globalConfig.isStorePage = true;
    getAddress();
    getShippingPrice();
  }, []);

  const getShippingPrice = async () => {
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append("action", "getGsharShippingFees");
    let { data } = await axios.post(
      process.env.REACT_APP_BASE_URL,
      bodyFormData
    );
    console.log(data);
    setShippingPrice(Number(data.data.price));
    setLoading(false);
  };

  const getAddress = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append("action", "getAddress");
    let { data } = await axios.post(
      process.env.REACT_APP_BASE_URL,
      bodyFormData,
      {
        headers: headers,
      }
    );
    console.log(data.data);
    setAddress(data.data);
    setSelectedAddress(data.data[data.data.length - 1]);
    setModal(false);
  };

  const handleOpenModal = () => {
    setAddressInput({
      name: "",
      city: "",
      state: "Abu Dhabi",
      country: "United Arab Emirates",
      landmark: "",
      address: "",
      phone: "",
    });
    setModal(true);
  };

  const handleAddAddress = async () => {
    if (!addressInput.name) {
      alert("Please enter name to proceed");
      return;
    }
    if (!addressInput.phone) {
      alert("Please enter phone number to proceed");
      return;
    }
    if (!addressInput.address) {
      alert("Please enter address to proceed");
      return;
    }
    const bodyFormData = new FormData();
    bodyFormData.append("action", "addAddress");
    bodyFormData.append("address", JSON.stringify(addressInput));
    let { data } = await axios.post(
      process.env.REACT_APP_BASE_URL,
      bodyFormData,
      {
        headers: headers,
      }
    );
    console.log(data);
    getAddress();
  };

  const handleConfirmation = (addressId) => {
    setTimeout(() => {
      if (
        window.confirm("Sure, you want to delete the selected address") == true
      ) {
        setSelectedAddress();
        handleDeleteAddress(addressId);
      }
    }, 100);
  };

  const handleDeleteAddress = async (addressId) => {
    const bodyFormData = new FormData();
    bodyFormData.append("action", "deleteAddress");
    bodyFormData.append("address_id", addressId);
    let { data } = await axios.post(
      process.env.REACT_APP_BASE_URL,
      bodyFormData,
      {
        headers: headers,
      }
    );
    setAddress(data.data);
  };

  const handlePlaceOrder = async () => {
    setBtnLoading(true);
    let cart = JSON.parse(localStorage.getItem("products"));
    cart = {
      products: cart,
      address_id: selectedAddress.id,
      shipping_id: "1",
    };
    const bodyFormData = new FormData();
    bodyFormData.append("action", "createPurchase");
    bodyFormData.append("items", JSON.stringify(cart));
    bodyFormData.append("shipping_id", "1");
    let paymentDetails;
    let { data } = await axios.post(
      process.env.REACT_APP_BASE_URL,
      bodyFormData,
      {
        headers: headers,
      }
    );
    console.log(data);
    if (data.success) {
      paymentDetails = data.purchase_number;
      const formData = new FormData();
      formData.append("action", "initiatePayment");
      formData.append("purchase_number", paymentDetails);
      formData.append(
        "redirect_to",
        "https://discovershurooq.ae/stores/gshar/orders"
      );
      let response = await axios.post(
        process.env.REACT_APP_BASE_URL,
        formData,
        {
          headers: headers,
        }
      );
      setBtnLoading(false);
      if (response.data.success) {
        window.location.href = response.data.payment_link;
      } else {
        alert(response?.data?.message || "Payment initiation failed");
        localStorage.setItem("products", "[]");
        history.push("/stores/gsharshop");
      }
    } else {
      alert(data?.message || "Order placement failed");
      localStorage.setItem("products", "[]");
      history.push("/stores/gsharshop");
    }
  };

  const cart = JSON.parse(localStorage.getItem("products"));
  const totalPrice = cart.reduce(function (sum, item) {
    return (
      Number(sum) +
      Number(item.qty * item.price) +
      Number(shippingPrice) +
      Number(taxPrice)
    ).toFixed(2);
  }, 0);
  const subTotal = cart.reduce(function (sum, item) {
    return (Number(sum) + Number(item.qty * item.price)).toFixed(2);
  }, 0);

  return (
    <div>
      {/* <RegisterBanner /> */}
      <StoreMenu />
      <CartBanner
        backgroundImage={images[Math.floor(Math.random() * images.length)]}
        titleArr={[
          { title: "Home", link: "/" },
          {
            title: "gsharshop",
            link: `/stores/gsharshop`,
          },
          {
            title: "Cart",
            link: `/stores/gsharshop/cart`,
          },
          {
            title: "Address",
            link: `/stores/${localStorage.getItem(
              "storeName"
            )}/checkout-address`,
          },
        ]}
        title={"Checkout"}
      />
      <div className="container py-5 h-100 mt-5">
        <Row className="d-flex justify-content-center align-items-center h-100">
          <Col>
            <Row className="">
              <Col lg={8} className="me-auto">
                <div className="d-flex align-items-center mb-4 d-flex justify-content-between">
                  <h5 className="mb-0 text-black">
                    Select a delivery address
                    {/* <span
                        className="fw-normal"
                        // style={{ marginLeft: "0.7rem" }}
                      >
                        {`(${products.length} Items)`}
                      </span> */}
                  </h5>
                  {/* <h5 className="text-black">Total: $ {totalPrice}</h5> */}
                </div>
              </Col>
              <Col lg={8}>
                <div className="addreswrapper">
                  {address.map((addr, index) => (
                    <div
                      className=""
                      style={{
                        width: "100%",
                        // padding: "20px",
                        // margin: "5px",
                        cursor: "pointer",
                        // borderRadius: "10px",
                        position: "relative",
                      }}
                    >
                      <label
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          margin: 0,
                        }}
                      >
                        <input
                          type="radio"
                          id="html"
                          name="fav_language"
                          value={index.toString()}
                          defaultChecked={address.length === index + 1}
                          onChange={() => setSelectedAddress(addr.address)}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "20px",
                            left: "20px",
                            width: "18px",
                            height: "18px",
                            display: "none",
                          }}
                        ></input>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            padding: "20px",
                            paddingLeft: "50px",
                            borderRadius: "4px",
                            position: "relative",
                            maxHeight: "300px",
                            height: "150px",
                            width: "350px",
                            minWidth: "350px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="radiodec"
                        >
                          <div>
                            <span className="radio"></span>
                            {addr.address.name}
                            <div style={{ fontWeight: "lighter" }}>
                              <div>Mobile: {addr.phone}</div>
                              <div>
                                {addr.address.address} {addr.address.landmark}{" "}
                                {addr.address.city} {addr.address.country}{" "}
                                {addr.address.state}
                              </div>
                            </div>
                          </div>
                          <img
                            style={{ width: "20px", height: "20px" }}
                            src={trash}
                            onClick={(e) => handleConfirmation(addr.id)}
                          />
                        </div>
                      </label>
                    </div>
                  ))}
                  <div
                    className="border p-3 p-lg-10"
                    style={{
                      height: "150px",
                      width: "350px",
                      // padding: "70px",
                      textAlign: "center",
                      // margin: "5px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      fontWeight: "bold",
                      color: "#f89828",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={handleOpenModal}
                  >
                    {" "}
                    + Add new address
                  </div>
                </div>

                <div className="pt-5">
                  <h6 className="mb-0">
                    <a href="/stores" className="text-body">
                      <i className="fas fa-long-arrow-alt-left me-2"></i>
                      Back to shop
                    </a>
                  </h6>
                </div>
              </Col>

              <Col lg={4} className="mt-3 mt-lg-0">
                <Card color="transparent">
                  <CardBody className="">
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "158px",
                        }}
                      >
                        <Loader color="#FE5734" type="Oval" />
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "none",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="mb-4 mt-2 pt-2 pb-2 d-flex border-bottom"
                            style={{
                              display: "none",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Col
                                lg={1}
                                md={1}
                                sm={1}
                                xs={1}
                                className="summary"
                              >
                                <img
                                  className="img-fluid product-image"
                                  src={coupon}
                                  alt=""
                                />
                              </Col>
                              <span style={{ marginLeft: "0.5rem" }}>
                                {"Coupons & Offers"}
                              </span>
                            </div>
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary btn-block btn-sm"
                                style={{
                                  background: "transparent !important",
                                  color: "#eb6139 !important",
                                  border: "1px solid",
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="border-bottom mb-2">
                          <div className="d-flex justify-content-between mb-2">
                            <h7>Total</h7>
                            <h6>AED {subTotal}</h6>
                          </div>

                          <div className="d-flex justify-content-between mb-2">
                            <h7>Shipping</h7>
                            <h6>AED {shippingPrice.toFixed(2)}</h6>
                          </div>

                          <div className="d-flex justify-content-between mb-2">
                            <h7>Tax</h7>
                            <h6>Included</h6>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: 18,
                              marginTop: "0.5rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            Grand total <br />
                            <span
                              style={{ fontWeight: "lighter", fontSize: 15 }}
                            >
                              Inclusive of all taxes
                            </span>
                          </span>
                          <h5
                            style={{
                              marginTop: "0.5rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            AED {totalPrice}
                          </h5>
                        </div>
                        {/* <div style={{ display: "flex", alignItems: "center" }}>
                      <h8>Average delivery time:</h8>&nbsp;
                      <h8 style={{ fontWeight: "bold" }}>4-24 hours</h8>
                    </div> */}

                        {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem",
                        backgroundColor: "#eb613933",
                        borderRadius: "4px",
                        padding: 4,
                        fontWeight: "bold",
                        color: "#ff6f61",
                      }}
                    >
                      AED 52 saved so far !
                    </div> */}

                        <div
                          style={{
                            // display: "flex",
                            justifyContent: "center",
                            marginTop: "1rem",
                          }}
                          className="border-top pt-3 d-none d-lg-flex"
                        >
                          <button
                            type="button"
                            className="actionbutton"
                            disabled={!selectedAddress}
                            data-mdb-ripple-color="dark"
                            onClick={handlePlaceOrder}
                            style={{
                              paddingLeft: 80,
                              paddingRight: 80,
                              padding: "15px",
                              width: "100%",
                            }}
                          >
                            {btnLoading && (
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            <a>
                              <>Place Order</>
                            </a>
                          </button>
                        </div>

                        {/* for small devices */}
                        <div
                          style={{
                            // display: "flex",
                            justifyContent: "center",
                            marginTop: "1rem",
                          }}
                          className="p-3 d-flex d-lg-none fixbottom"
                        >
                          {/* <div style={{flex: "1 0 40%"}}>
                      <h4 className="mb-0">
                      $ {totalPrice}
                      </h4>
                      <p
                      style={{color: "#ff6f61",textDecoration:"underline",margin:"0",fontSize:"14px"}}
                      >view price details</p>
                    </div> */}
                          <button
                            type="button"
                            className="submitbutton"
                            data-mdb-ripple-color="dark"
                            disabled={!selectedAddress}
                            onClick={handlePlaceOrder}
                            style={{
                              paddingLeft: 80,
                              paddingRight: 80,
                              padding: "15px",
                              width: "100%",
                              fontWeight: 700,
                              color: "#fff",
                            }}
                          >
                            <a>
                              <>Place Order</>
                            </a>
                          </button>
                        </div>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal
              isOpen={modal}
              centered
              keyboard
              size="lg"
              backdrop={false}
              scrollable={false}
              toggle={() => setModal(!modal)}
              onOpened={() => {
                document.body.style.overflow = "hidden";
              }}
              onClosed={() => {
                document.body.style.overflow = "auto";
              }}
            >
              <div
                style={{
                  gap: "15px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4
                    style={{
                      margin: 20,
                    }}
                  >
                    Add new address
                  </h4>
                  <span
                    style={{
                      margin: 20,
                      cursor: "pointer",
                    }}
                    onClick={() => setModal(false)}
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </span>
                </div>
                <Col lg={12} md={12} sm={12} xs={12} className="address-input">
                  <Col
                    lg={5}
                    md={5}
                    sm={5}
                    xs={5}
                    className="address-text"
                    style={{ marginBottom: "10px" }}
                  >
                    Name *
                    <Input
                      placeholder="Enter Name"
                      name="name"
                      onChange={(e) => {
                        setAddressInput({
                          ...addressInput,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    ></Input>
                  </Col>
                  <Col lg={5} md={5} sm={5} xs={5} className="address-text">
                    Mobile Number *
                    <PhoneInput
                      defaultCountry="AE"
                      placeholder="Enter mobile number"
                      country={"ae"}
                      name="phone"
                      disableDropdown
                      value={addressInput.phone}
                      onChange={(value) => {
                        setAddressInput({
                          ...addressInput,
                          phone: value,
                        });
                      }}
                    />
                  </Col>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Col lg={11} md={11} sm={11} xs={11}>
                    <div>
                      Address *
                      <Input
                        placeholder="Enter address"
                        name="address"
                        onChange={(e) => {
                          setAddressInput({
                            ...addressInput,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      ></Input>
                    </div>
                  </Col>
                </Col>
                <Col
                  lg={12}
                  style={{
                    marginTop: 10,
                  }}
                  className="address-input"
                >
                  <Col lg={5} md={5} sm={5} xs={5} className="address-text">
                    Emarati
                    <Input
                      type="select"
                      name="state"
                      onChange={(e) => {
                        setAddressInput({
                          ...addressInput,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    >
                      {[
                        "Abu Dhabi",
                        "Dubai",
                        "Sharjah",
                        "Ajman",
                        "Umm Al-Quwain",
                        "Fujairah",
                        "Ras Al Khaimah",
                      ].map((name, id) => (
                        <option key={id} value={name}>
                          {name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col
                    lg={5}
                    md={5}
                    sm={5}
                    xs={5}
                    className="address-text"
                    // style={{ marginTop: "10px" }}
                  >
                    City
                    <Input
                      placeholder="Enter city"
                      name="city"
                      onChange={(e) => {
                        setAddressInput({
                          ...addressInput,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    ></Input>
                  </Col>
                </Col>
                <Col
                  lg={12}
                  style={{
                    marginTop: 10,
                  }}
                  className="address-input"
                >
                  <Col lg={5} md={5} sm={5} xs={5} className="address-text">
                    Landmark
                    <Input
                      placeholder="Enter landmark"
                      name="landmark"
                      onChange={(e) => {
                        setAddressInput({
                          ...addressInput,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    ></Input>
                  </Col>
                  <Col
                    lg={5}
                    md={5}
                    sm={5}
                    xs={5}
                    className="address-text"
                    // style={{ marginTop: "10px" }}
                  >
                    Country
                    <Input
                      placeholder="Enter country"
                      name="country"
                      value={addressInput.country}
                      readOnly
                      onChange={(e) => {
                        setAddressInput({
                          ...addressInput,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    ></Input>
                  </Col>
                </Col>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 15,
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-block btn-md"
                    data-mdb-ripple-color="dark"
                    style={{ backgroundColor: "#f89828", color: "#fff" }}
                    onClick={handleAddAddress}
                  >
                    <a>
                      <>Add address</>
                    </a>
                  </button>
                </div>
              </div>
            </Modal>
          </Col>
        </Row>
      </div>
    </div>
  );
}
