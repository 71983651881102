import React, { useEffect, useState, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Row, Col, Modal, Spinner } from "reactstrap";
import "./style.scss";
import frame1 from "../../assets/temp/frame1.png";
import frame2 from "../../assets/temp/frame2.png";
import frame3 from "../../assets/temp/frame3.png";
import full1 from "../../assets/temp/full1.png";
import banner from "../../assets/temp/postcards-bg.jpg";
import globalConfig from "../../globalConfig";
import ContentLoader from "react-content-loader";
import SwipeableBottomSheet from "react-swipeable-bottom-sheet";
import PostCardsBanner from "../../components/banners/PostCardsBanner";

const EditHostCards = () => {
  const [uploaded, setUploaded] = useState(false);
  const [uploadImg, setUploadImg] = useState();
  const [frameImg, setFrameImg] = useState();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const history = useHistory();
  const galleryRef = useRef(null);
  const inputRef = useRef(null);
  const replaceRef = useRef(null);
  const [frameData, setFrameData] = useState([]);

  const headers = {
    Authorization: "Bearer " + localStorage.getItem("jwt"),
  };
  localStorage.setItem("pathname", history.location.pathname);

  useEffect(() => {
    if (frameData.length) {
      setFrameImg(frameData.find((frame) => frame.id === params.card_slug));
    } else {
      handleUploadImage();
    }
  }, [params.card_slug]);

  const handleUploadImage = async () => {
    if (frameData.length) {
      setLoading(true);
      setUploadImg(
        inputRef.current?.files[0]
          ? URL.createObjectURL(inputRef.current.files[0])
          : localStorage.getItem("postImage")
      );
      setLoading(false);
      setUploaded(false);
    } else {
      const bodyFormData = new FormData();
      bodyFormData.append("action", "postCards");
      await axios
        .post(process.env.REACT_APP_BASE_URL, bodyFormData, {
          headers: headers,
        })
        .then((res) => {
          console.log(res.data);
          setFrameImg(
            res.data.data.find((frame) => frame.id === params.card_slug)
          );
          setFrameData(res.data.data.sort((a, b) => b.id - a.id));
          setUploaded(true);
        });
    }
  };

  const handleFrameChange = () => {
    setUploadImg(URL.createObjectURL(replaceRef.current.files[0]));
  };

  const handleNextChange = () => {
    localStorage.setItem("postImage", uploadImg);
    localStorage.setItem("postCard", frameImg.image_base64);
    localStorage.setItem("postCardPrice", frameImg.price);
    localStorage.setItem("posCardId", frameImg.id);
    history.push("/hos-postcards/message");
  };

  const imageRenderLoader = () => (
    <div>
      <ContentLoader
        speed={2}
        viewBox="0 0 638 520"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="1" y="0" rx="3" ry="3" width="638" height="480" />
      </ContentLoader>
    </div>
  );

  const renderLoader = () =>
    ["", "", ""].map((data, index) => (
      <div className="frame-img">
        <ContentLoader
          speed={2}
          viewBox="0 0 638 520"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="1" y="0" rx="3" ry="3" width="600" height="500" />
        </ContentLoader>
      </div>
    ));

  return (
    <div className="productdetail">
      <PostCardsBanner
        linkPage={[
          { type: "link", name: "Home", link: "/" },
          { name: "Postcards" },
        ]}
        backgroundImage={banner}
        destinationName="Share your moments"
        destinationDescription=" "
        destinationHoverDescription="Share your precious moments with friends and family as postcards through Emirates Post. Once created, you can download/print the postcards and drop it at your nearest Emirates Post box."
      />
      <div className="container py-5 mt-5">
        <>
          <div className="preview-postcards">
            <div className="frame-container">
              <div className="frame-div">
                {frameData.length > 0
                  ? frameData.map(
                      (frame) =>
                        frame.customisable === "1" && (
                          <Link to={`/hos-postcards/edit-my-card/${frame.id}`}>
                            <div className="frame-img">
                              {frameImg && (
                                <img
                                  style={{
                                    border:
                                      frameImg.id === frame.id &&
                                      "3px solid red",
                                    borderRadius: "15px",
                                  }}
                                  className="frame-img1"
                                  src={`https://bo.discovershurooq.ae/cdn-cgi/image/width=680,height=400,quality=100/files/${frame.image}`}
                                  alt=""
                                />
                              )}
                              {uploadImg && (
                                <img
                                  style={{
                                    border:
                                      frameImg.id === frame.id &&
                                      "3px solid red",
                                    borderRadius: "15px",
                                  }}
                                  className="frame-img2"
                                  src={uploadImg}
                                  alt=""
                                />
                              )}
                            </div>
                          </Link>
                        )
                    )
                  : renderLoader()}
              </div>
              <div className="parent">
                {frameImg ? (
                  <img
                    className="image1"
                    src={`https://bo.discovershurooq.ae/cdn-cgi/image/width=680,height=400,quality=100/files/${frameImg.image}`}
                    alt=""
                  />
                ) : (
                  imageRenderLoader()
                )}
                {uploadImg && (
                  <img
                    className="image2"
                    style={{
                      backgroundImage: `url(${uploadImg})`,
                    }}
                    // src={uploadImg}
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className="preview-section">
              <div className="preview-title">Personalize your postcard</div>
              <div className="preview-desc">
                It’s simple and easy to add a personal touch to postcards.
                Upload your photo, choose a template, add your message and
                submit. Your picture-perfect postcard will be ready to download
                or print.
              </div>
              <div className="hoscard-btns">
                {frameData.length > 0 && (
                  <>
                    <input
                      ref={replaceRef}
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFrameChange}
                    />
                    <button
                      className="hoscard-btn fill-btn"
                      onClick={() => replaceRef.current?.click()}
                    >
                      {uploadImg ? "Replace Image" : "Upload Image"}
                    </button>
                  </>
                )}
                <button
                  className="hoscard-btn fill-btn"
                  onClick={handleNextChange}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      </div>
      <Modal
        isOpen={uploaded}
        centered
        keyboard
        size="lg"
        backdrop={false}
        scrollable={false}
        toggle={() => setUploaded(!uploaded)}
        onOpened={() => {
          document.body.style.overflow = "hidden";
        }}
        onClosed={() => {
          document.body.style.overflow = "auto";
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "20px",
          }}
        >
          <span
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "flex-end",
              padding: "20px 20px 10px 10px",
            }}
            onClick={() => setUploaded(false)}
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
          <div className="upload-text">
            <div className="upload-title">Upload your photo</div>
            <div className="upload-desc">
              Choose the picture you like to turn into a personalized photocard
              from the photo gallery and upload it.
            </div>
          </div>
          <div className="upload-container">
            <input
              ref={inputRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleUploadImage}
            />
            <button
              disabled={loading}
              className="hoscard-upload fill-btn"
              onClick={() => inputRef.current?.click()}
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  UPLOADING IMAGE
                </>
              ) : (
                <>UPLOAD YOUR IMAGE</>
              )}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(withRouter(EditHostCards));
