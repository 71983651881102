import PackageBanner from "../../components/banners/PackageBanner";
import PackageCard from "./PackageCard";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as userAction from "../../actions/user-action-type";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";

const Package = (props) => {
  const { packageDetails, getPackageDetails } = props;
  const { package_slug } = props.match.params;
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    setLoading(true);
    const data = new FormData();
    data.append("action", "packagesDetails");
    data.append("slug", package_slug);
    await getPackageDetails(data);
    setTimeout(() => setLoading(false), 1000);
  }, [package_slug]);

  return (
    <>
      <Helmet>
        <title>{packageDetails?.package_name}</title>
        <meta name="description" content={packageDetails?.description} />
        {/* Add other meta tags as needed */}
      </Helmet>
      {loading ? (
        <div
          style={{
            marginTop: "20%",
            marginBottom: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader color="#FE5734" type="Oval" />
        </div>
      ) : (
        <>
          <PackageBanner
            // linkPage={[
            //   { name: packageDetails?.package_name },
            // ]}
            backgroundImage={packageDetails?.img}
            sliderImage={packageDetails?.mega_slider_images}
          />
          <PackageCard />
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ user: { packageDetails } }) => ({
  packageDetails,
});

const mapDispatchToProps = {
  getPackageDetails: userAction.getPackageDetails,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Package));
