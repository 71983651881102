import {useEffect} from "react"
import Routes from "./routes";



function App() {
	useEffect(() => {
		let cart = localStorage.getItem('products');

		if(!cart) localStorage.setItem('products', '[]')
	}, [])

  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
