import { all, call, put, takeEvery } from "redux-saga/effects";
import Cookies from 'universal-cookie';

import {
  SET_CART_DATA,
  setCartData,
  // GET_HOME_DATA,
  // getHomeDataRequested,
  // getHomeDataFailed,
  // getHomeDataSuccess
} from "../actions/cart-action-type";
import httpClient from "./http-client";


function* setCartDataHandler(data) {
  setCartData();
  console.log(data)
}

function* Cart() {

  yield all([
    takeEvery(SET_CART_DATA, setCartDataHandler)
  ]);
}

export default Cart;
