import React, { useState, useEffect, useMemo } from "react";
import ProductList from "../../components/productList";
import Filters from "../../components/filters";
import { Row, Col, Card } from "reactstrap";
import StoreMenu from "../../components/layout/header/store-menu";
import axios from "axios";
import globalConfig from "../../globalConfig";
import cartbanner from "../../assets/images/cartbanner.jfif";
import CartBanner from "../../components/banners/CartBanner";
import { Helmet } from "react-helmet";
import ContentLoader from "react-content-loader";
import { useParams } from "react-router-dom";
import TagsFilter from "../../components/TagsFilter";
import ProductPagination from "../../components/product-pagination";

export default function Products() {
  const [products, setProducts] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);
  const [collectionsList, setCollectionsList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    globalConfig.isStorePage = true;
    const bodyForm = new FormData();
    bodyForm.append("action", "storeData");
    axios.post(process.env.REACT_APP_BASE_URL, bodyForm).then((res) => {
      console.log(res.data.data);
      setProducts(res.data.data.products);
      setTempProducts(res.data.data.products);
      setLoading(false);
    });
    const bodyFormData = new FormData();
    bodyFormData.append("action", "listCollections");
    bodyFormData.append("destination_slug", params.slug);
    axios.post(process.env.REACT_APP_BASE_URL, bodyFormData).then((res) => {
      setCollectionsList(res.data.data);
    });
  }, []);

  const handleFilterTags = (selectedTag) => {
    if (selectedTag === "All") {
      setProducts(tempProducts);
      setTagsList((prev) => {
        prev.map((list) => {
          list.active = false;
          return list;
        });
        return prev;
      });
    } else {
      setTagsList((prev) => {
        prev.map((list) => {
          if (list.value === selectedTag) {
            list.active = true;
          } else {
            list.active = false;
          }
          return list;
        });
        return prev;
      });
      const filteredProducts = tempProducts.filter(
        (temp) =>
          temp.tags && temp.tags.find((tag) => tag.value === selectedTag)
      );
      setProducts(filteredProducts);
    }
  };

  const renderLoader = () => (
    <Row className="m-0">
      {["", "", "", "", "", "", "", ""].map((data, index) => (
        <Col className="mb-4" lg={3} md={6}>
          <ContentLoader
            speed={2}
            viewBox="0 0 638 520"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="1" y="0" rx="3" ry="3" width="600" height="800" />
          </ContentLoader>
        </Col>
      ))}
      ;
    </Row>
  );

  const filteredData = useMemo(() => {
    document
      .getElementById("products-container")
      ?.scrollIntoView({ behavior: "smooth" });
    let tempTableData = products.filter((prod) => prod.hide === "0");

    setTotalData(tempTableData.length);
    return tempTableData.slice(
      (currentPage - 1) * 20,
      (currentPage - 1) * 20 + 20
    );
  }, [products, currentPage]);

  return (
    <div className="productdetail">
      <StoreMenu />
      <CartBanner
        backgroundImage={cartbanner}
        titleArr={[
          { title: "Home", link: "/" },
          {
            title: "gsharshop",
            link: `/stores/gsharshop`,
          },
        ]}
        title={"Products"}
      />
      <div id="products-container" className="container py-5 mt-5">
        <Row className="m-3">
          <Filters
            products={products}
            setProducts={setProducts}
            tempProducts={tempProducts}
            collectionsList={collectionsList}
            showCollectionFilters={false}
          />
          {/* <TagsFilter tempProducts={tempProducts} setProducts={setProducts} /> */}
        </Row>
        {!loading ? (
          <>
            {!!filteredData.length &&
            filteredData.find(
              (product) => Number(product.qty) > 0 && product.hide === "0"
            ) ? (
              <>
                <Row className="m-0">
                  {filteredData.map(
                    (product) =>
                      Number(product.qty) > 0 &&
                      product.hide === "0" && (
                        <Col
                          className="mb-4"
                          key={product.product_id}
                          lg={3}
                          md={6}
                        >
                          <ProductList product={product}></ProductList>
                        </Col>
                      )
                  )}
                </Row>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <ProductPagination
                    total={totalData}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </span>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "4rem",
                  fontSize: 30,
                  fontWeight: 600,
                }}
              >
                No products Found
              </div>
            )}
          </>
        ) : (
          renderLoader()
        )}
      </div>
      <div data-mc-src="a5802126-e9f2-440b-b846-38b5f4306f0f#instagram"></div>
      <Helmet>
        <script
          src="https://cdn2.woxo.tech/a.js#628a1677c32c1400211e14f6"
          async
          data-usrc
        ></script>
      </Helmet>
    </div>
  );
}
