import React, { useEffect, useState } from "react";
import { Link, useHistory, withRouter, useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import RegisterBanner from "../../components/banners/RegisterBanner";
import "./style.scss";
import OwlCarousel from "react-owl-carousel2";
import emptyCart from "../../assets/images/Empty-cart.png";
import StoreMenu from "../../components/layout/header/store-menu";
import cartbanner from "../../assets/images/cartbanner.jfif";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Spinner } from "reactstrap";
import coupon from "../../assets/images/discount.png";
import trash from "../../assets/images/icons/trash.svg";
import { setCartData } from "../../actions/cart-action-type";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@material-ui/core";
import axios from "axios";
import Loader from "react-loader-spinner";
import EcommerceGuarantees from "../../components/ecommerce-guarantees";
import globalConfig from "../../globalConfig";
import CollectionBanner from "../../components/banners/CollectionBanner";
import CartBanner from "../../components/banners/CartBanner";
import cartB from "../../assets/images/cart-banner-lq.jpg";
import random2 from "../../assets/images/random/Bags.jpeg";
import random3 from "../../assets/images/random/collections.jpeg";
import random4 from "../../assets/images/random/collections-option-3.jpg";
import random5 from "../../assets/images/random/Gshar-Products34.jpg";
import random6 from "../../assets/images/random/Homepage1.jpeg";
import random7 from "../../assets/images/random/Homepage2.jpeg";

const Cart = (props) => {
  const [products, setProducts] = useState([]);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [taxPrice, setTaxPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [warn, setWarn] = useState(false);
  const storeData = useSelector((store) => store);

  const headers = {
    Authorization: "Bearer " + localStorage.getItem("jwt"),
  };
  const options = {
    nav: false,
    loop: false,
    autoplay: false,
    loop: false,
    items: 1,
    autoWidth: true,
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [notifyState, setNotifyState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = notifyState;
  localStorage.setItem("pathname", history.location.pathname);

  // const images = [
  //   random1,
  //   random2,
  //   random3,
  //   random4,
  //   random5,
  //   random6,
  //   random7,
  // ];

  useEffect(() => {
    globalConfig.isStorePage = true;
    window.scrollTo({ behavior: "smooth", top: 0 });
    let cart = JSON.parse(localStorage.getItem("products"));
    setProducts(cart ? cart : []);
    const getShippingPrice = async () => {
      setLoading(true);
      const bodyFormData = new FormData();
      bodyFormData.append("action", "getGsharShippingFees");
      let { data } = await axios.post(
        process.env.REACT_APP_BASE_URL,
        bodyFormData
      );
      console.log(data);
      setShippingPrice(Number(data.data.price));
      setLoading(false);
    };
    getShippingPrice();
  }, []);

  const removeItem = (index) => {
    let cart = JSON.parse(localStorage.getItem("products"));
    cart.splice(index, 1);
    localStorage.setItem("products", JSON.stringify(cart));
    setProducts(cart);
  };

  const changeQty = async (param, index) => {
    let cart = JSON.parse(localStorage.getItem("products"));
    let count;

    const bodyFormData = new FormData();
    bodyFormData.append("action", "getProduct");
    bodyFormData.append("slug", cart[index].product_slug);

    let { data } = await axios.post(
      process.env.REACT_APP_BASE_URL,
      bodyFormData
    );
    data = data.data;

    if (param == "up") {
      cart[index].qty++;
    } else {
      cart[index].qty--;
    }

    let maxQty = 99999999999999;

    if (data.variation_attributes) {
      if (cart[index].variant_id) {
        let keys = Object.keys(data.variation_attributes);
        for (var i = 0; i < keys.length; i++) {
          if (data.variation_attributes[keys[i]].id === cart[index].variant_id)
            maxQty = data.variation_attributes[keys[i]].qty;
        }
      } else {
        maxQty = data.qty;
      }
    }

    if (cart[index].qty > 0 && cart[index].qty <= maxQty) {
      localStorage.setItem("products", JSON.stringify(cart));
      setProducts(cart);
      dispatch(setCartData());
    } else {
      promptErrorMessage();
    }
  };

  const promptErrorMessage = () => {
    setAlertText(`Sorry, Seller has Limited Quantity Available `);
    setWarn(false);
    setNotifyState({ ...notifyState, open: true });
  };

  const promptWarnMessage = () => {
    setAlertText(`Please add products to your cart`);
    setWarn(true);
    setNotifyState({ ...notifyState, open: true });
  };

  const totalPrice = products.reduce(function (sum, item) {
    return (
      Number(sum) +
      Number(item.qty * item.price) +
      Number(shippingPrice) +
      Number(taxPrice)
    ).toFixed(2);
  }, 0);

  const subTotal = products.reduce(function (sum, item) {
    return (Number(sum) + Number(item.qty * item.price)).toFixed(2);
  }, 0);

  const handlePageRedirect = () => {
    if (products.length) {
      setBtnLoading(true);
      setTimeout(() => {
        history.push(
          localStorage.getItem("loginStatus") === "success"
            ? `/stores/gsharshop/checkout-address`
            : "/register"
        );
      }, 1000);
    } else {
      promptWarnMessage();
    }
  };

  const handleClose = () => {
    setNotifyState({ ...notifyState, open: false });
  };

  return (
    <div className="cart">
      <StoreMenu />
      <CartBanner
        backgroundImage={cartB}
        titleArr={[
          {
            title: "Home",
            link: "/",
          },
          {
            title: "gsharshop",
            link: `/stores/gsharshop`,
          },
          {
            title: "Cart",
            link: `/stores/gsharshop/cart`,
          },
        ]}
        title={"Shopping Cart"}
      />

      {/* <RegisterBanner /> */}

      <div className="container py-5 h-100 mt-5">
        <Row className="cart-container d-flex justify-content-center align-items-center h-100">
          <Col lg={8} className="me-auto">
            {/* <div className="d-flex align-items-center mb-4 d-flex justify-content-between">
              <h5 className="mb-0 text-black">
                Shopping Cart
                <span
                  className="fw-normal d-block d-md-inline"
                  // style={{ marginLeft: "0.7rem" }}
                >
                  {`(${products.length} Items)`}
                </span>
              </h5>
              <h5 className="text-black">
                Total:{" "}
                <span className="d-block d-md-inline">AED {totalPrice}</span>
              </h5>
            </div> */}
          </Col>
          <Col lg={12}>
            <Row className="p-3">
              {products.length > 0 ? (
                <Col lg={7}>
                  {products.map((product, index) => (
                    <Card
                      style={{
                        backgroundColor: "#EFF5FC",
                        border: "none",
                        marginBottom: "0.5rem",
                        borderRadius: "10px",
                      }}
                    >
                      <CardBody style={{ padding: "0.5rem 0.5rem" }}>
                        <div className="d-flex position-relative gap-3 cart-item">
                          <Card
                            className="product-img-card"
                            style={{
                              height: "fit-content",
                              minWidth: "180px",
                            }}
                          >
                            {product.images && product.images.length > 0 ? (
                              <Link
                                to={`/stores/${params.slug}/product_detail/${product.product_slug}`}
                              >
                                <img
                                  className="product-image"
                                  src={product.images[0]}
                                  alt={product.product_title}
                                />
                              </Link>
                            ) : (
                              ""
                            )}
                          </Card>
                          <div className="d-flex flex-column justify-content-between w-100 productdetail gap-2">
                            <div style={{ padding: "2px", marginLeft: "5px" }}>
                              <Link
                                to={`/stores/${params.slug}/product_detail/${product.product_slug}`}
                              >
                                <h8 className="product-title">
                                  {product.title}
                                </h8>
                              </Link>
                              <br />
                              {product.variant_options &&
                                product.variant_options.map((variant, i) => (
                                  <span>
                                    <h8 className="">
                                      {" "}
                                      {variant}{" "}
                                      {product.variant_options.length !=
                                        i + 1 && "|"}
                                    </h8>
                                  </span>
                                ))}
                              <div
                                className="product__desc"
                                style={{
                                  width: "80%",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  color: "#2E323B",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    product.product_description?.substring(
                                      0,
                                      140
                                    ),
                                }}
                              ></div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "3px",
                              }}
                            >
                              <h4 className="price">AED {product.price}</h4>
                              <div className="counter-container d-flex">
                                <div className="counter-btn">
                                  <button
                                    className="btn btn-link"
                                    onClick={(e) => changeQty("down", index)}
                                  >
                                    <i className="fas fa-minus"></i>
                                  </button>
                                  <p>{product.qty}</p>
                                  <button
                                    className="btn btn-link"
                                    onClick={(e) => changeQty("up", index)}
                                  >
                                    <i className="fas fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                              className="position-absolute top-0 end-0 d-flex justify-content-center align-items-center"
                              style={{
                                width: "40px",
                                height: "40px",
                                background: "#ffffff4f",
                                borderRadius: "5px",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={trash}
                                onClick={(e) => removeItem(index)}
                              />
                              {/* <a
                                href="#!"
                                className="text-muted"
                                onClick={(e) => removeItem(index)}
                              >
                                REMOVE
                              </a> */}
                            </div>
                          </div>

                          {/* <div
                              key={index}
                              className="border-bottom pb-3 row mb-4 d-flex justify-content-between align-items-center"
                            >
                              <div className="col-md-2 col-lg-2 col-xl-2">
                              <img
                                src={`https://bo.discovershurooq.ae/files/${product.images}`}
                                className="img-fluid rounded-3" alt={product.product_title}/>
                            </div>
                            </div> */}
                        </div>
                      </CardBody>
                    </Card>
                  ))}

                  {/* <div className="pt-5">
                      <h6 className="mb-0">
                        <a href="/stores" className="text-body">
                          <i className="fas fa-long-arrow-alt-left me-2"></i>
                          Back to shop
                        </a>
                      </h6>
                    </div> */}
                </Col>
              ) : (
                <Col
                  lg={7}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <img src={emptyCart} alt="" width="100%"></img>
                  <span style={{ fontSize: 26, fontWeight: "bold" }}>
                    Your Cart is Empty
                  </span>
                  <span style={{ fontSize: 18 }}>
                    Looks like you have not added anything to your cart
                  </span>
                </Col>
              )}

              <Col lg={5} className="mt-3 mt-lg-0">
                <Card
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#EFF5FC",
                    border: "none",
                  }}
                >
                  <CardBody className="">
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "158px",
                        }}
                      >
                        <Loader color="#FE5734" type="Oval" />
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "none",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="mb-4 mt-2 pt-2 pb-2 d-flex border-bottom"
                            style={{
                              display: "none",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Col
                                lg={1}
                                md={1}
                                sm={1}
                                xs={1}
                                className="summary"
                              >
                                <img
                                  className="img-fluid product-image"
                                  src={coupon}
                                  alt=""
                                />
                              </Col>
                              <span style={{ marginLeft: "0.5rem" }}>
                                {"Coupons & Offers"}
                              </span>
                            </div>
                            <div>
                              <button
                                type="button"
                                className="btn btn-block btn-sm"
                                style={{
                                  backgroundColor: "#F89828",
                                  color: "white",
                                  border: "1px solid",
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="border-bottom mb-2"
                          style={{
                            color: "#2E323B",
                            fontSize: "18px",
                            fontWeight: 500,
                          }}
                        >
                          <div className="d-flex justify-content-between mb-2">
                            <h6>Total</h6>
                            <h6>AED {subTotal}</h6>
                          </div>
                          <div className="border-top" />
                          <div className="d-flex justify-content-between mb-2 mt-3">
                            <h6>Shipping</h6>
                            <h6>AED {shippingPrice.toFixed(2)}</h6>
                          </div>
                          <div className="border-top" />
                          <div className="d-flex justify-content-between mb-2  mt-3">
                            <h6>Tax</h6>
                            <h6>Included</h6>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mb-2 mt-3">
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: 18,
                              marginTop: "0.5rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            Grand Total <br />
                            <span
                              style={{ fontWeight: "lighter", fontSize: 15 }}
                            >
                              Inclusive of all taxes
                            </span>
                          </span>
                          <h3
                            className="cart-total"
                            style={{
                              marginTop: "0.5rem",
                              marginBottom: "0.5rem",
                              fontWeight: "bold",
                            }}
                          >
                            AED {totalPrice}
                          </h3>
                        </div>
                        {/* <div style={{ display: "flex", alignItems: "center" }}>
                      <h8>Average delivery time:</h8>&nbsp;
                      <h8 style={{ fontWeight: "bold" }}>4-24 hours</h8>
                    </div> */}

                        {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem",
                        backgroundColor: "#eb613933",
                        borderRadius: "4px",
                        padding: 4,
                        fontWeight: "bold",
                        color: "#ff6f61",
                      }}
                    >
                      AED 52 saved so far !
                    </div> */}

                        <div
                          style={{
                            // display: "flex",
                            justifyContent: "center",
                            marginTop: "1rem",
                          }}
                          className="border-top pt-3 d-none d-lg-flex"
                        >
                          <button
                            type="button"
                            // className="btn btn-block btn-md cart-continue"
                            className="actionbutton"
                            disabled={btnLoading}
                            data-mdb-ripple-color="dark"
                            style={{
                              // paddingLeft: 80,
                              // paddingRight: 80,
                              // padding: "10px",
                              width: "100%",
                              // borderRadius: "10px",
                              // color: "white",
                              // backgroundColor: "#F89828",
                            }}
                            onClick={handlePageRedirect}
                          >
                            {storeData.user.token ||
                            localStorage.getItem("jwt") ? (
                              <>
                                {btnLoading && (
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                )}
                                Continue
                              </>
                            ) : (
                              <>Register</>
                            )}
                          </button>
                        </div>

                        {/* for small devices */}
                        <div
                          style={{
                            // display: "flex",
                            justifyContent: "center",
                            marginTop: "1rem",
                            zIndex: 10,
                          }}
                          className="p-3 d-flex d-lg-none fixbottom"
                        >
                          <div style={{ flex: "1 0 40%" }}>
                            <h4 className="mb-0">AED {totalPrice}</h4>
                            <p
                              style={{
                                color: "#F89828",
                                textDecoration: "underline",
                                margin: "0",
                                fontSize: "14px",
                              }}
                            >
                              view price details
                            </p>
                          </div>
                          <button
                            type="button"
                            // className="btn btn-block btn-md cart-continue"
                            className="actionbutton"
                            disabled={btnLoading}
                            data-mdb-ripple-color="dark"
                            style={{
                              // paddingLeft: 65,
                              // paddingRight: 65,
                              // padding: "15px",
                              width: "100%",
                              // borderRadius: "10px",
                              // backgroundColor: "#F89828",
                              // color: "#fff",
                            }}
                            onClick={handlePageRedirect}
                          >
                            {storeData.user.token ||
                            localStorage.getItem("jwt") ? (
                              <>
                                {btnLoading && (
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                )}
                                Continue
                              </>
                            ) : (
                              <>Register</>
                            )}
                          </button>
                        </div>
                      </>
                    )}
                  </CardBody>
                </Card>
                <Snackbar
                  open={open}
                  autoHideDuration={5000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical, horizontal }}
                  style={{ marginBottom: "5rem" }}
                >
                  <Alert
                    severity={warn ? "warning" : "error"}
                    onClose={handleClose}
                    style={{ backgroundColor: warn ? "orange" : "red" }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        marginRight: "1rem",
                      }}
                    >
                      {alertText}{" "}
                    </span>
                  </Alert>
                </Snackbar>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div style={{ background: "#E4EBF3", padding: "60px 0" }}>
        <div
          className="container"
          style={{
            background: "#E7881A",
            borderRadius: "15px",
          }}
        >
          <div
            className="row"
            style={{
              padding: "50px 75px",
            }}
          >
            <EcommerceGuarantees />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(withRouter(Cart));
