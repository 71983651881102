import React, { useEffect } from "react";
import UploadBanner from "../../components/banners/UploadBanner";
import { Row, Col, Input, Button } from "reactstrap";
import cx from "classnames";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import * as userAction from "../../actions/user-action-type";
import { withRouter } from "react-router-dom";
import { getLanguage, setLanguage, useTranslation } from "react-multi-lang";
import ArrowIcon from "../../assets/images/icons/left-arrow.svg";
import Filter from "../../assets/images/icons/setting.svg";
import "./style.scss";

function UploadContent() {
  const btns = [
    { name: "About", active: "true" },
    { name: "Terms" },
    { name: "Faq" },
  ];
  return (  
    <div className="UploadContent">
      <div className="tab-package-btn w-100">
        <div className="wrapper">
          {btns.map(({ name, active = false }, index) => (
            <button key={index} className={cx({ active: active })}>
              {name}
            </button>
          ))}
        </div>
      </div>
      <p>
        Get ready for an exciting off-road experience as you discover the most
        spectacular vistas Mleiha has to offer. Your journey will last
        approximately 6 hours as you take in views from two different peaks,
        visit ancient sites, stop for picture-perfect photos of the stunning
        landscape and conclude your adventure in the relaxing setting of our
        Sunset Lounge.
      </p>
    </div>
  );
}

const PurchaseHistory = (props) => {
  const t = useTranslation();
  const isEnglish = localStorage.getItem("user_language");
  const { orderHistoryData, getOrderHistoryData } = props;
  const history = useHistory();
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });

    // const data = new FormData();
    // data.append("action", "orderHistory");
    // getOrderHistoryData(data);
  }, []);
  console.log(orderHistoryData);
  return (
    <div class="order-history">
      <UploadBanner
        linkPage={[
          { type: "link", name: "Home", link: "/" },
          { name: "Settings" },
        ]}
      />
      <section className="uploadSection">
        <div className="UploadCard">
          <Row>
            {/* <Col md={3}>
               <UploadContent />
            </Col> */}
            <Col md={12} className="mx-auto">
              <div className="destinations-package">
                <div className="">
                  <div className="backContainer d-flex justify-content-between">
                    <p className="menu-text">
                      <i
                        className="fas fa-arrow-left arrow"
                        onClick={() => history.push("/settings")}
                      ></i>
                      {t("settings.order_history")}
                    </p>
                    {/* <img src={ArrowIcon} height="20px" alt="Back icon" onClick={() => history.push({ pathname: "/settings" })} />
                    <div className="password_text">{t('settings.order_history')}</div> */}
                    <button className="gap-2 align-items-center btn d-flex filter-btn">
                      <img src={Filter} />
                      Filter
                    </button>
                  </div>

                  {orderHistoryData.length == 0 ? (
                    <Col
                      className="mb-3"
                      style={{
                        textAlign: "center",
                        marginTop: "200px",
                        justifyContent: "center",
                      }}
                    >
                      <p style={{ textAlign: "center" }}>
                        {t("order_history.empty_message")}
                      </p>
                    </Col>
                  ) : (
                    <div className="orderwrapper">
                      {new Array(6).fill(1).map((item, index) => (
                        <div
                          key={index}
                          className=""
                          onClick={() =>
                            item.status == 1 &&
                            history.push(
                              `../booking-details/${item.order_number}`
                            )
                          }
                          style={{
                            border: '1px solid #d2d2d2',
                            // margin: '0 11px',
                            padding: '15px',
                            borderRadius: '5px'
                          }}
                        >
                          <span className="gap-3 d-flex align-items-center">
                            {/* <img
                              className="rounnded-3"
                              src={
                                "https://api.discovershurooq.ae/files/" +
                                item.package_img
                              }
                              alt=""
                              width={50}
                              height={50}
                            /> */}
                            {/* <span
                              className=""
                              style={{
                                color: "#eb5c33",
                                textTransform: "lowercase",
                              }}
                            >
                          Order #64658
                            </span> */}
                             <span
                              className="rounded-circle"
                              style={{
                                background: "#eb6139",
                                width: "10px",
                                height: "10px",
                              }}
                            ></span>
                             <p style={{ margin: "0" }}>Pending</p>
                          </span>
                          <span className="gap-2 mt-4 d-flex flex-column">
                            <span className="d-flex justify-content-between">
                              <p style={{ margin: "0", fontSize: "16px",fontWeight:'400' }}>
                               Order #214340
                              </p>{" "}
                              <p style={{ margin: "0", fontSize: "16px",fontWeight:'400' }}>
                               21/03/2021
                              </p>
                            </span>
                            <span style={{fontSize:"14px",color:'grey'}} className="d-flex justify-content-between">
                            <p style={{ margin: "0",}}>
                               Qty 2
                              </p>
                            <p style={{ margin: "0",}}>
                               AED 40
                              </p>
                            </span>
                          </span>
                          {/* <span className={"c d-flex" + (item.status == 0 ? `${t('order_history.processing')}` : item.status == 1 ? 'qr' : `${t('order_history.failed')}`)}>
                              {item.status == 0 ? `${t('order_history.processing')}` : item.status == 1 ? `${t('order_history.show_qr')}` : `${t('order_history.failed')}`}
                              <span className="rounded-full" style={{background:'#eb6139',width:'10px',height:'10px'}}></span><p>Pending</p>
                            </span> */}
                          <span className="gap-2 d-flex align-items-center">
                            {/* <span
                              className="rounded-circle"
                              style={{
                                background: "#eb6139",
                                width: "10px",
                                height: "10px",
                              }}
                            ></span>
                            <p style={{ margin: "0" }}>Pending</p> */}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Col>
            {/* <Col md={3}>
            </Col> */}
          </Row>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = ({ user: { orderHistoryData } }) => ({
  orderHistoryData,
});

const mapDispatchToProps = {
  getOrderHistoryData: userAction.getOrderHistoryData,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PurchaseHistory));
