import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import RegisterBanner from "../../components/banners/RegisterBanner";
import StoreMenu from "../../components/layout/header/store-menu";
import axios from "axios";
import Parser from "html-react-parser";
import { useParams } from "react-router-dom";
import innerdesignersbanner from "../../../src/assets/images/innerdesignersbanner.jfif";
import { Row, Col, Card } from "reactstrap";
import OwlCarousel from "react-owl-carousel2";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import { setCartData } from "../../actions/cart-action-type";
import TrustedLogo from "../../../src/assets/images/logos/trusted.svg";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@material-ui/core";
import ProductList from "../../components/productList";
import EcommerceGuarantees from "../../components/ecommerce-guarantees";
import globalConfig from "../../globalConfig";
import CollectionBanner from "../../components/banners/CollectionBanner";
import random1 from "../../assets/images/random/About-us.jpeg";
import random2 from "../../assets/images/random/Bags.jpeg";
import random3 from "../../assets/images/random/collections.jpeg";
import random4 from "../../assets/images/random/collections-option-3.jpg";
import random5 from "../../assets/images/random/Gshar-Products34.jpg";
import random6 from "../../assets/images/random/Homepage1.jpeg";
import random7 from "../../assets/images/random/Homepage2.jpeg";

const Tags = (props) => {
  const [tagsData, setTagsData] = useState([]);
  const params = useParams();

  const images = [
    random1,
    random2,
    random3,
    random4,
    random5,
    random6,
    random7,
  ];

  useEffect(() => {
    globalConfig.isStorePage = true;
    const bodyFormData = new FormData();
    bodyFormData.append("action", "listProductsBySlug");
    bodyFormData.append("destination_slug", params.slug);
    bodyFormData.append("tags", params.tag_slug);

    window.scrollTo({ behavior: "smooth", top: 0 });

    axios.post(process.env.REACT_APP_BASE_URL, bodyFormData).then((res) => {
      console.log(res.data);
      setTagsData(res.data.data);
    });
  }, []);

  return (
    <div className="productdetail">
      <StoreMenu />
      <CollectionBanner
        backgroundImage={images[Math.floor(Math.random() * images.length)]}
        titleArr={[
          {
            title: "Home",
            link: "/",
          },
          {
            title: params.tag_slug,
            link: `/stores/gsharshop/tag/${params.tag_slug}`,
          },
        ]}
        title={params.tag_slug}
      />
      <div className="container py-5 mt-5">
        <div style={{ fontSize: 24, fontWeight: "bold", margin: "10px" }}>
          {params.tag_slug}
        </div>
        <Row className="m-0">
          {tagsData.map(
            (tag) =>
              Number(tag.qty) > 0 && (
                <Col className="mb-4" key={tag.product_id} lg={3} md={6}>
                  <ProductList product={tag}></ProductList>
                </Col>
              )
          )}
        </Row>
      </div>
    </div>
  );
};

export default connect()(withRouter(Tags));
