import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import RegisterBanner from "../../components/banners/RegisterBanner";
import StoreMenu from "../../components/layout/header/store-menu";
import axios from "axios";
import aboutbg from "../../assets/images/aboutbg.png";
import OwlCarousel from "react-owl-carousel2";
import aboutimage from "../../assets/images/aboutimage.png";
import Parser from "html-react-parser";
import { useParams } from "react-router-dom";
import { Row, Col, Card } from "reactstrap";
import AboutUs from "../../assets/images/About-us.jpeg";
import Contact from "../../assets/images/Contact-us.jpeg";
import aboutSec from "../../assets/images/about-inside.jpeg";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import { setCartData } from "../../actions/cart-action-type";
import TrustedLogo from "../../../src/assets/images/logos/trusted.svg";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@material-ui/core";
import ProductList from "../../components/productList";
import EcommerceGuarantees from "../../components/ecommerce-guarantees";
import styled from "styled-components";
import globalConfig from "../../globalConfig";
import CollectionBanner from "../../components/banners/CollectionBanner";
import { Helmet } from "react-helmet";
import { Button } from "reactstrap";
import "./style.scss";

const About = (props) => {
  const params = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    globalConfig.isStorePage = true;
    const bodyForm = new FormData();
    bodyForm.append("action", "pageDetails");
    bodyForm.append("destination_slug", "gsharshop");
    bodyForm.append("page_slug", params.page_slug);
    axios.post(process.env.REACT_APP_BASE_URL, bodyForm).then((res) => {
      console.log(res.data.data);
      setData(res.data.data);
    });
  }, [params.page_slug]);

  const options = {
    nav: true,
    loop: true,

    dots: false,
    nav: false,
    items: 1,
  };

  const options1 = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  const Slider = styled.div`
    display: flex;
    // margin-top: 60px;
    .owl-stage {
      display: flex;
      height: calc(550px + 50px);
    }
    .owl-dots {
      position: absolute;
      left: 0;
      top: 90%;
    }
    .owl-prev {
      position: absolute;
      top: 40%;
      left: 0%;
      display: block !important;
    }
    .owl-next {
      position: absolute;
      top: 40%;
      right: 0%;
      display: block !important;
    }
    img {
      height: 100%;
      object-fit: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  `;

  return (
    <div className="productdetail">
      <StoreMenu />
      <CollectionBanner
        backgroundImage={params.page_slug === "about" ? AboutUs : Contact}
        titleArr={[
          {
            title: "Home",
            link: "/",
          },
          {
            title: params.page_slug === "about" ? "About" : "Contact Us",
            link: "",
          },
        ]}
        title={params.page_slug === "about" ? "About" : "Contact"}
      />

      {params.page_slug === "about" ? (
        <div className="container about-cont">
          <div className="section1">
            <div className="textsec">
              <h3
                style={{
                  color: "#202434",
                  fontSize: "40px",
                  fontWeight: 600,
                }}
              >
                Who are we
              </h3>
              <p
                style={{
                  color: "#2E323B",
                  fontSize: "16px",
                  fontWeight: 500,
                  marginTop: "30px",
                  marginBottom: "20px",
                }}
              >
                Welcome to Gshar, where the vibrant spirit of Emirati culture
                comes to life through our handcrafted treasures. Gshar, derived
                from Emirati slang meaning "things," embodies the essence of
                creativity and craftsmanship.
              </p>
              <p
                style={{
                  color: "#2E323B",
                  fontSize: "16px",
                  fontWeight: 500,
                  marginTop: "30px",
                  marginBottom: "20px",
                }}
              >
                Our store is a hidden gem packed with handcrafted wonders,
                showcasing the talent of artists from the UAE and beyond.
                Founded over 5 years ago under Shurooq, we're dedicated to
                supporting artists and offering a platform for collaborations!
              </p>
            </div>
            <div className="imgsec">
              <img
                src={aboutbg}
                alt=""
                style={{
                  position: "absolute",
                  right: "-60px",
                }}
              />
            </div>
          </div>

          {/* <img
            src={
              "https://bo.discovershurooq.ae/files/RYN058206603d0be93168.jpeg"
            }
            alt=""
            style={{
              width: "100%",
              borderRadius: "10px",
            }}
          /> */}
          <Slider>
            <OwlCarousel options={options}>
              {[
                "Homepage2634d97301fc13.jpeg",
                "RYN058206603d0be93168.jpeg",
                "RYN023606603d11dd47ff.jpeg",
              ].map((img, key) => (
                <img
                  key={key}
                  style={{
                    filter: "brightness(50%)",
                    backgroundSize: "cover",
                    backgroundPositionY: "center",
                    backgroundColor: "gray",
                    // position: "absolute",
                    // right: "-60px",
                  }}
                  alt={img}
                  src={`https://bo.discovershurooq.ae/files/${img}`}
                />
              ))}
            </OwlCarousel>
          </Slider>

          <p className="textsec2">
            While rooted in Emirati culture, Gshar's focus is evolving.
            Initially spotlighting local artists and UAE-inspired artwork, we
            now welcome artists from all backgrounds, celebrating the beauty and
            diversity of the region. Join us in celebrating creativity without
            borders at Gshar.
          </p>
        </div>
      ) : (
        <div
          className="container"
          style={{ fontSize: 24, padding: "5rem", fontWeight: 600 }}
        >
          {/* <iframe
            id="JotFormIFrame-222829298518469"
            title="Modern Contact Form"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allowfullscreen="true"
            allow="geolocation; microphone; camera"
            src="https://form.jotform.com/222829298518469"
            frameborder="0"
            style={{ minWidth: "100%", height: "539px", border: "none" }}
            scrolling="no"
          >
            {" "}
          </iframe>
          <Helmet></Helmet> */}
          {/* <a
            href="https://api.whatsapp.com/send/?phone=971564005045&lang=ar"
            target="_blank"
          > */}
          <span>
            Whatsapp
            {/* </a> */}
            /Call: {/* <a href="tel:0564005046"> */}
            <a href="https://wa.me/+971564005045" target="_blank">+971564005045</a>
          </span>
          <br />
          Email:{" "}
          <a href="mailto:gshar.hos@gmail.com" target="_blank">
            gshar.hos@gmail.com
          </a>
          {/* </a> */}
          <br />
          Instagram (
          <a href="https://www.instagram.com/gsharshop/" target="_blank">
            @gsharshop
          </a>
          )
          <br />
          Store working hours: 10:00 AM - 10:00 PM (Daily)
          <br />
          Address link:{" "}
          <a
            href="https://www.google.com/maps/dir/25.321777,55.3759635/gshar/@25.3360722,55.3612905,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5f5ba78a66b6a5:0x92757622433bd22e!2m2!1d55.3844965!2d25.3598384?entry=ttu"
            target="_blank"
          >
            Heart of Sharjah, Souq Al Shanasiyah
          </a>
          <br />
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                backgroundColor: "#F89828",
                color: "#FFFFFF",
                fontSize: 15,
                fontWeight: 600,
                border: "none",
                borderRadius: "7px",
                // width: "100%",
              }}
              onClick={() =>
                window.open(
                  "https://survey.shurooq.gov.ae/s/To-collaborate-as-a-designer/tt-tDU2enkRxvdxEV6ZV6hTmN",
                  "_blank"
                )
              }
            >
              <span style={{ whiteSpace: "nowrap" }}>
                Collaborate as a designer
              </span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect()(withRouter(About));
