import React, { useEffect, useState } from "react";
import UploadBanner from "../../components/banners/UploadBanner";
import { Row, Col, Input, Button } from "reactstrap";
import cx from "classnames";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import * as userAction from "../../actions/user-action-type";
import { withRouter, useParams } from "react-router-dom";
import { getLanguage, setLanguage, useTranslation } from "react-multi-lang";
import ArrowIcon from "../../assets/images/icons/left-arrow.svg";
import moment from "moment";
import "./style.scss";

const HosCardsOrderDetails = (props) => {
  const params = useParams();
  const { hosCardsOrderHistoryData, getHosCardsOrderHistory } = props;
  const [hosCardsHistory, setHosCardsHistory] = useState();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    const data = new FormData();
    data.append("action", "listPostCardOrders");
    getHosCardsOrderHistory(data);
  }, []);

  useEffect(() => {
    console.log(hosCardsOrderHistoryData);
    const selectedHosCard = hosCardsOrderHistoryData.find(
      (hosCard) => hosCard.order_number === params.order_number
    );
    console.log(selectedHosCard);
    setHosCardsHistory(selectedHosCard);
  }, [hosCardsOrderHistoryData]);

  useEffect(() => {
    if (hosCardsHistory) {
      document
        .querySelector("#imageid1")
        .setAttribute("src", hosCardsHistory.back_side);
      document
        .querySelector("#imageid2")
        .setAttribute("src", hosCardsHistory.front_side);
    }
  }, [hosCardsHistory]);

  return (
    <div>
      <UploadBanner
        linkPage={[
          { type: "link", name: "Home", link: "/" },
          { name: "Hos Cards Order Details" },
        ]}
      />
      <section className="uploadSection">
        <div className="UploadCard">
          <Row>
            <div style={{ textAlign: "center", fontSize: 20 }}>
              {hosCardsHistory && (
                <div style={{ marginBottom: "2rem" }}>
                  <div className="success-checkmark">
                    <div className="check-icon">
                      <span className="icon-line line-tip"></span>
                      <span className="icon-line line-long"></span>
                      <div className="icon-circle"></div>
                      <div className="icon-fix"></div>
                    </div>
                  </div>

                  <span className="cake-card-content">
                    <div
                      className="cake-card-heading-text"
                      style={{ marginTop: "1rem" }}
                    >
                      Order Number :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {hosCardsHistory.order_number}
                      </span>
                    </div>
                    <div className="cake-card-heading-text">
                      Date :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {moment(hosCardsHistory.created_at).format(
                          "DD/MM/YYYY"
                        )}{" "}
                        {hosCardsHistory.created_at.split(" ")[1]}
                      </span>
                    </div>
                    <div className="cake-card-heading-text">
                      Frame Name :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {hosCardsHistory.name}
                      </span>
                    </div>
                    <div className="cake-card-heading-text">
                      Price :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {hosCardsHistory.amount}
                      </span>
                    </div>
                    <div className="cake-card-heading-text">
                      Status :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {hosCardsHistory.status == 0
                          ? "Pending"
                          : hosCardsHistory.status == 1
                          ? hosCardsHistory.processing_status
                            ? `${hosCardsHistory.processing_status}`
                            : ""
                          : "Failed"}
                      </span>
                    </div>
                  </span>
                </div>
              )}
            </div>
            {hosCardsHistory && (
              <Col md={12}>
                <div className="destinations-package">
                  <div className="">
                    <div>
                      <div className="parentOrder">
                        <img
                          alt="post-card"
                          className="imageOrder"
                          id="imageid2"
                        />
                        <img
                          id="imageid1"
                          className="imageOrder"
                          alt="post-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = ({ user: { hosCardsOrderHistoryData } }) => ({
  hosCardsOrderHistoryData,
});

const mapDispatchToProps = {
  getHosCardsOrderHistory: userAction.getHosCardsOrderHistory,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HosCardsOrderDetails));
