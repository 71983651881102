import headBanner from "../../../assets/images/backgrounds/head-banner.png";
import PostImage1 from "../../../assets/images/HOSxPost_1.jpg";
import PostImage2 from "../../../assets/images/HOSxPost_2.jpg";
import LinkPage from "../../LinkPage";
import "./style.scss";
import { Container } from "reactstrap";
import OwlCarousel from "react-owl-carousel2";
import { style } from "@mui/system";

export default function PostCardsBanner(props) {
  const {
    backgroundImage = headBanner,
    linkPage = [],
    destinationName = "",
    destinationDescription = "",
    destinationHoverDescription = "",
  } = props;

  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 10000,
    lazyLoad: true,
    animateOut: "fadeOut",
    dots: true,
    nav: false,
    items: 1,
  };

  return (
    <div className="Post-wrapper">
      {!backgroundImage.includes("null") ? (
        <OwlCarousel options={options}>
          {[PostImage1, PostImage2].map((img) => (
            <section
              className="post-banner"
              style={{
                backgroundImage: `linear-gradient( rgba(8, 8, 37, 0.87), rgba(0, 0, 0, 0.44)), url(${img})`,
                backgroundSize: "cover",
                backgroundPositionY: "center",
              }}
            >
              <Container style={{ marginBottom: "1rem" }}>
                <div />
                <div>
                  <h1>{destinationName}</h1>
                  <p className="main-dec mobile-only">
                    {destinationDescription}
                  </p>
                  <p className="main-dec  desktop-only">
                    {destinationHoverDescription}
                  </p>
                </div>
              </Container>
            </section>
          ))}
        </OwlCarousel>
      ) : (
        <section
          className="head-banner"
          style={{
            backgroundImage: `gradient( rgba(8, 8, 37, 0.30), rgba(0, 0, 0, 0.30)),url(${headBanner})`,
          }}
        >
          <Container>
            <div />
            <div>
              <h1>{destinationName}</h1>
              <p className="main-dec mobile-only">{destinationDescription}</p>
              <p className="main-dec  desktop-only">
                {destinationHoverDescription}
              </p>
            </div>
          </Container>
        </section>
      )}
    </div>
  );
}
