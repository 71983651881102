import React, { useEffect, useState, useCallback, useRef } from "react";
import { toPng } from "html-to-image";
import { withRouter, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import postCardImage from "../../assets/temp/postcard-image.png";
// import postCardImageNew from "../../assets/temp/HOS_PostCard.png";
import { Row, Col, Spinner } from "reactstrap";
import "./style.scss";
import OwlCarousel from "react-owl-carousel2";
import html2canvas from "html2canvas";
import { Modal } from "reactstrap";
import globalConfig from "../../globalConfig";
import banner from "../../assets/temp/postcards-bg.jpg";
import PostCardsBanner from "../../components/banners/PostCardsBanner";

const MessageHosCards = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [address, setAddress] = useState("");
  const [fontStyle, setFontStyle] = useState("Select Font");
  const [fontSize, setFontSize] = useState({
    name: "Select Size",
    size: "100%",
  });
  const [openModal, setOpenModal] = useState(false);
  const storeData = useSelector((store) => store);
  const history = useHistory();

  const headers = {
    Authorization: "Bearer " + storeData.user.token,
  };
  localStorage.setItem("pathname", history.location.pathname);

  var arabicRegex = /[\u0600-\u06FF]/;
  var englishRegex = /[A-Za-z]/;

  useEffect(() => {
    // window.scrollTo({ behavior: "smooth", top: 0 });
    document
      .querySelector("#imageid")
      .setAttribute("src", localStorage.getItem("postCard"));
    if (!storeData.user.token) {
      setOpenModal(true);
    }
  }, [storeData.user.token]);

  const handleSumbitPostCard = async () => {
    setLoading(true);

    var card;
    var image;
    await html2canvas(document.querySelector("#card"), {
      scale: 3,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("card").style.display = "block";
      },
    }).then(function (canvas) {
      card = canvas.toDataURL("image/png");
      // const link = document.createElement("a");
      // link.download = "card.png";
      // link.href = myImage;
      // link.click();
    });
    await html2canvas(document.querySelector("#image"), {
      scale: 2,
    }).then(function (canvas) {
      image = canvas.toDataURL("image/png");
      // const link = document.createElement("a");
      // link.download = "image.png";
      // link.href = myImage;
      // link.click();
    });
    const bodyFormData = new FormData();
    bodyFormData.append("action", "addPostCardOrder");
    bodyFormData.append("post_card_id", localStorage.getItem("posCardId"));
    bodyFormData.append("message", message);
    bodyFormData.append("address", address);
    bodyFormData.append("image", localStorage.getItem("postImage"));
    bodyFormData.append("front_side", image);
    bodyFormData.append("back_side", card);
    axios
      .post(process.env.REACT_APP_BASE_URL, bodyFormData, {
        headers: headers,
      })
      .then((res) => {
        console.log(res.data);
        window.location.href = res.data.payment_link;
      });
  };

  const handleFontStyleOpen = () => {
    document.getElementById("style-dropdown").classList.toggle("show");
  };

  const handleFontSizeOpen = () => {
    document.getElementById("size-dropdown").classList.toggle("show");
  };

  const detectAddressLanguage = (input) => {
    var textAddress = document.getElementById("input-address");
    var labelAddress = document.getElementById("label-address");
    if (arabicRegex.test(input)) {
      textAddress.dir = "rtl";
      labelAddress.style.textAlign = "right";
      labelAddress.dir = "rtl";
    } else if (englishRegex.test(input)) {
      textAddress.dir = "ltr";
      labelAddress.style.textAlign = "left";
      labelAddress.dir = "ltr";
    }
  };

  const detectMessageLanguage = (input) => {
    var textMessage = document.getElementById("input-message");
    var labelMessage = document.getElementById("label-message");
    if (arabicRegex.test(input)) {
      textMessage.dir = "rtl";
      labelMessage.style.textAlign = "right";
      labelMessage.dir = "rtl";
    } else if (englishRegex.test(input)) {
      textMessage.dir = "ltr";
      labelMessage.style.textAlign = "left";
      labelMessage.dir = "ltr";
    }
  };

  window.onclick = function (event) {
    if (!event.target.matches(".filter-item")) {
      var dropdowns = document.getElementsByClassName("style-content");
      for (var i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains("show")) {
          openDropdown.classList.remove("show");
        }
      }
    }
  };

  return (
    <div className="productdetail">
      <PostCardsBanner
        linkPage={[
          { type: "link", name: "Home", link: "/" },
          { name: "Postcards" },
        ]}
        backgroundImage={banner}
        destinationName="Share your moments"
        destinationDescription=" "
        destinationHoverDescription="Share your precious moments with friends and family as postcards through Emirates Post. Once created, you can download/print the postcards and drop it at your nearest Emirates Post box."
      />
      <div className="container py-5">
        <div className="message-container">
          <div className="message-stack">
            <div className="message-title">Add your personal message</div>
            <div className="message-desc">
              Every picture has a story to tell.
              <br /> Share your experience with friends or family with a
              personalized message
            </div>
          </div>
          <div className="message-input">
            <Row className="m-3">
              <textarea
                id="input-address"
                className="text-address"
                rows={3}
                placeholder=" Enter receiver address"
                onChange={(e) => setAddress(e.target.value)}
                onInput={(e) => detectAddressLanguage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setAddress(e.target.value + "\n");
                  }
                }}
                maxLength={88}
              />
            </Row>
            <Row className="m-3">
              <textarea
                id="input-message"
                className="text-message"
                rows={6}
                placeholder=" Enter your message"
                onChange={(e) => setMessage(e.target.value)}
                onInput={(e) => detectMessageLanguage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setMessage(e.target.value + "\n");
                  }
                }}
                maxLength={380}
              />
            </Row>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <div className="dropdown" style={{ maxWidth: "180px" }}>
                <button className="filter-item" onClick={handleFontStyleOpen}>
                  {fontStyle}
                </button>
                <div id="style-dropdown" className="style-content">
                  {["Sarah", "Charlie", "Lauren", "Alexander", "Roboto"].map(
                    (style) => (
                      <div
                        className="sub-filter"
                        onClick={() => setFontStyle(style)}
                      >
                        {style}
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="dropdown" style={{ maxWidth: "180px" }}>
                <button className="filter-item" onClick={handleFontSizeOpen}>
                  {fontSize.name}
                </button>
                <div id="size-dropdown" className="style-content">
                  {[
                    { name: "Normal", size: "100%" },
                    { name: "Large", size: "135%" },
                    { name: "X-Large", size: "150%" },
                  ].map((size) => (
                    <div
                      className="sub-filter"
                      onClick={() => setFontSize(size)}
                    >
                      {size.name}
                    </div>
                  ))}
                </div>
              </div>
            </Row>
          </div>
        </div>
        <div className="postcard-container">
          <div className="parent-postcard" id="card" style={{ width: "100%" }}>
            <img
              className="postcard"
              src={postCardImage}
              width="100%"
              height="100%"
            />
            <label
              id="label-message"
              className="message"
              style={{
                fontFamily: fontStyle === "Select Font" ? "inherit" : fontStyle,
                fontSize: fontSize.size,
                unicodeBidi: "bidi-override",
                whiteSpace: "pre-wrap",
              }}
            >
              {message}
            </label>
            <label
              id="label-address"
              className="address"
              style={{
                unicodeBidi: "bidi-override",
                whiteSpace: "pre-wrap",
              }}
            >
              {address}
            </label>
          </div>
          <div className="parent" id="image">
            <img alt="post-card" className="image1" id="imageid" />
            {localStorage.getItem("postImage") && (
              <img
                className="image2"
                alt="post-image"
                // style={{
                //   backgroundImage: `url(${localStorage.getItem("postImage")})`,
                // }}
                src={localStorage.getItem("postImage")}
              />
            )}
          </div>
        </div>
        <div className="hoscard-submit mb-4 mt-4">
          <button
            className="hoscard-btn fill-btn ms-2"
            onClick={() => {
              history.push("/hos-postcards");
            }}
          >
            Back
          </button>
          <button
            className="hoscard-btn fill-btn ms-2"
            onClick={() =>
              storeData.user.token ? handleSumbitPostCard() : setOpenModal(true)
            }
          >
            {storeData.user.token ? (
              <>
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Submitting Order ( AED{" "}
                    {localStorage.getItem("postCardPrice")} )
                  </>
                ) : (
                  <>
                    Submit Order ( AED {localStorage.getItem("postCardPrice")} )
                  </>
                )}
              </>
            ) : (
              <>Login</>
            )}
          </button>
        </div>
      </div>
      <Modal
        isOpen={openModal}
        centered
        keyboard
        size="lg"
        backdrop={false}
        scrollable={false}
        toggle={() => setOpenModal(!openModal)}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "20px",
          }}
        >
          <div style={{ padding: "20px 10px" }}>
            <div className="upload-title">Login Required</div>
          </div>
          <div className="hoscard-submit mb-4">
            <button
              className="hoscard-btn fill-btn ms-2"
              onClick={() => history.push("/register")}
            >
              Sign Up
            </button>
            <button
              className="hoscard-btn fill-btn ms-2"
              onClick={() => history.push("/login")}
            >
              Login
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(withRouter(MessageHosCards));
