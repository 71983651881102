import UploadBanner from "../../components/banners/UploadBanner";
import { Row, Col, Input, Button, Spinner } from "reactstrap";
import cx from "classnames";
import { FilePond, registerPlugin } from "react-filepond";
import ScratchCard from "react-scratchcard";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { connect } from "react-redux";
import * as userAction from "../../actions/user-action-type";
import { withRouter } from "react-router-dom";
import { getLanguage, setLanguage, useTranslation } from "react-multi-lang";
import { Component, useState } from "react";
import { Link } from "react-router-dom";
import { LineArrow } from "../../components/SvgComponents";
import winImage from "../../assets/images/scratchwin.png";

import "./scratchandwin.scss";

import scratchSurface from "../../assets/images/backgrounds/scratch-surface.jpg";
import { useEffect } from "react";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const About = () => (
	<div className="text-content">
		<p>Spend AED 50 at any Shurooq destination and win exciting prizes and experiences.</p>
	</div>
);

const Terms = () => (
	<div className="text-content">
		<ul>
			{" "}
			<li>Each AED 50 purchase with original/scanned receipt will be eligible to one raffle entry. The total value can be in a single or cumulative purchase.</li>
			<li>Purchases must be made in any outlet at the following Shurooq destinations and services: Al Majaz Waterfront, Al Montazah, Al Noor Island, Al Qasba, City Sightseeing Sharjah, Heart of Sharjah, Khorfakkan Beach, Mleiha, Sharjah Boats Tour, The Flag Island. </li>
			<li>Customers can enter by scanning their invoices online or submitting a voucher into the raffle box available in select restaurants and locations. Only one option can be selected per invoice. </li>
			<li>The prizes include hotel stays and tickets to activities and packages from a range of Shurooq’s destinations. </li>
			<li>
				The winners will be notified by phone and email within a week after the prize draw is held. If a winner does not respond to Shurooq within 14 days of being notified, then the winner's prize will be forfeited and Shurooq will be entitled to select another winner in accordance with the process described above. The new winner will have to respond to the notification of their win
				within 14 days as well, or else their prize will be forfeited.
			</li>
			<li>Winners can only win one prize regardless of how many raffles they have accumulated. </li>
			<li>If a winner rejects their prize, or the entry is invalid, or is breaching any of these Terms & Conditions, the winner's prize will be forfeited and Shurooq reserves the right to select another winner.</li>
			<li>The management holds the right to announce the winner’s name on any media chosen by Shurooq and its affiliates.</li>
			<li>Valid identification and official participation raffle ticket must be presented to claim the prize</li>
			<li>Prizes cannot be replaced or exchanged for cash or refunded. </li>
			<li>Owners and employees of Shurooq and affiliate outlets, their suppliers and their immediate families are not eligible to participate in this promotion.</li>
			<li>The management reserves the right to cancel or amend these Terms & Conditions at any time without prior notice.</li>
		</ul>
	</div>
);

const Faq = () => (
	<div className="text-content">
		<p>
			Get ready for an exciting off-road experience as you discover the most spectacular vistas Mleiha has to offer. Your journey will last approximately 6 hours as you take in views from two different peaks, visit ancient sites, stop for picture-perfect photos of the stunning landscape and conclude your adventure in the relaxing setting of our Sunset Lounge. Get ready for an exciting
			off-road experience as you discover the most spectacular vistas Mleiha has to offer. Your journey will last approximately 6 hours as you take in views from two different peaks, visit ancient sites, stop for picture-perfect photos of the stunning landscape and conclude your adventure in the relaxing setting of our Sunset Lounge.
		</p>
	</div>
);

function ScratchContent() {
	const btns = [
		{ name: "About", id: 1 },
		{ name: "Terms", id: 2 },
		{ name: "Faq", id: 3 },
	];
	const [activeTab, setActiveTab] = useState(1);

	return (
		<div className="UploadContent">
			<div className="tab-package-btn">
				<div
					className=""
					style={{
						overflowX: "auto",
						display: "flex",
						paddingTop: 25,
						paddingBottom: 25,
						paddingLeft: 15,
						paddingRight: 15,
					}}
				>
					{btns.map(({ id, name }) => (
						<button key={id} className={cx("btn", { active: id === activeTab })} onClick={() => setActiveTab(id)}>
							{name}
						</button>
					))}
				</div>
			</div>
			<div className="content">
				{activeTab === 1 && <About />}
				{activeTab === 2 && <Terms />}
				{activeTab === 3 && <Faq />}
			</div>

			<div className="links" style={{ textAlign: "center" }}>
			 
				
				
			</div>
		</div>
	);
}
const ScratchnWin = (props) => {
	// const [showDoneButton, setShowDoneButton] = useState(false);
	// const [files, setFiles] = useState(false);
	const [scratchFinished, setScratchFinished] = useState(false);
	const [scratchResult, setScratchResult] = useState(null);
	const [isLoadingScratch, setLoadingScratch] = useState(false);

	const { token, history } = props;

	useEffect(() => {
		localStorage.setItem("pathname", history.location.pathname);
	}, [history]);

	// const fileUploadToServer = () => {
	// 	const data = new FormData();
	// 	data.append("action", "uploadInvoice");
	// 	data.append("filepond", files);
	// 	uploadFile && uploadFile(data);
	// };

	// const onFileUpload = (fileItems) => {
	// 	setFiles(fileItems.map((fileItem) => fileItem.file));
	// 	fileUploadToServer();
	// };
	// const handleInit = () => {
	// 	setShowDoneButton(true);
	// };

	const getOffer = () => {
		if (!token?.length) return;
		// alert("You Won!!!");
	};

	const settings = {
		width: 320,
		height: 320,
		image: scratchSurface,
		finishPercent: 65,
		onComplete: () => {
			setScratchFinished(true);
			getOffer();
		},
	};

	useEffect(() => {
		if (!token?.length) return;
		const fd = new FormData();
		fd.append("action", "getScratchWin");
		setLoadingScratch(true);
		fetch("https://bo.discovershurooq.ae/api/index.php", {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			method: "POST",
			body: fd,
		})
			.then((res) => res.json())
			.then(setScratchResult)
			.catch((err) => alert(err.message || "Something went wrong while preparing for scratch."))
			.finally(() => setLoadingScratch(false));
	}, [token]);

	
	return (
		<div>
			<UploadBanner linkPage={[{ type: "link", name: "Home", link: "/" }, { name: "Scratch & Win" }]} />
			<section className="uploadSection">
				<div className="UploadCard">
					<Row>
						<Col md={6}>
							<div className="scratchWrapper">
								<div className="scratchHeader">
									<h5>Scratch here</h5>
								</div>

								{isLoadingScratch ? (
									<Spinner>Loading...</Spinner>
								) : (
									<ScratchCard {...settings}>
										<div
											className="scratchResult"
											style={{
												filter: !scratchFinished ? "blur(10px)" : "blur(0px)",
												backgroundImage: token?.length && scratchResult && scratchResult.data !== "" ? `url(${winImage})` : "none",
												backgroundSize: "cover",
												backgroundPosition: "center bottom",
											}}
										>
											<div className="scratchContent">
												{!token?.length ? (
													<>
														<h4>You won!!!</h4>
														<p>Please, login to see.</p>
														<Button className="arrow" color="primary" onClick={() => history.push(`/login`)}>
															Login
														</Button>
													</>
												) : scratchResult === null || scratchResult.data === "" ? (
													<>
														<p>There was something wrong.</p>
														<p>Please, refresh the page.</p>
													</>
												) : (
													<>
														<h4>You won!!!</h4>

														<p className="name">{scratchResult.data.name}</p>
														<span className="voucher_code">{scratchResult.data.voucher_codes}</span>
														<p className="description">{scratchResult.data.description}</p>
														
														        <img className="render-image" src={`https://api.discovershurooq.ae/files/${scratchResult.data.logo}`}  alt="" />
															
															


														
														
													</>
												)}
											</div>
										</div>
									</ScratchCard>
								)}
							</div>
						</Col>
						<Col md={6}>
							<ScratchContent />
						</Col>
					</Row>
					<Row>
						<img className="upload-banner-image" src="https://bo.discovershurooq.ae/files/S&W-logo61895267b1835.png" alt="banner" style={{ margin: "auto", display: "none" }} />
					</Row>
				</div>
			</section>
		</div>
	);
};

function ScratchAndWinPage(props) {
	const t = useTranslation();
	return <ScratchnWin t={t} {...props} />;
}
const mapStateToProps = ({ user: { loginData, loginStatus, token } }) => ({
	loginData,
	loginStatus,
	token,
});

const mapDispatchToProps = {
	uploadFile: userAction.uploadFile,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScratchAndWinPage));
