import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import Menus from "../menus/index";
import axios from "axios";
import cx from "classnames";
import { useSelector } from "react-redux";
import shoppingBag from "../../../../assets/images/icons/shopping-bag.svg";
import clock from "../../../../assets/images/icons/clock.svg";
import login from "../../../../assets/images/icons/login.svg";
import bag from "../../../../assets/images/bag-icon.png";
import storeLogo from "../../../../assets/images/Gshar_Logo_NEW.png";
import globalConfig from "../../../../globalConfig";

function MenuIcon() {
  return (
    <svg
      fill="#fff"
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="menu"
      width="1em"
      height="1em"
      aria-hidden="true"
    >
      <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"></path>
    </svg>
  );
}

function StoreMenu({ productDetail }) {
  const [storeName, setStoreName] = useState("");
  const [menu, setMenu] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [storeMenu, setStoreMenu] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  const storeData = useSelector((store) => store);
  // const subMenuData = [
  //   { name: "OPEN NOW", link: "/" },
  //   { name: "SERVICES", link: "/" },
  //   { name: "STORE LOCATOR", link: "/" },
  //   { name: "GETTING HERE", link: "/" },
  //   { name: `FAQ`, link: "/" },
  // ];

  const handleScroll = () => {
    setIsSticky(() => (window.scrollY > 70 ? true : false));
  };

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    const bodyFormData = new FormData();
    bodyFormData.append("action", "storeDesign");
    bodyFormData.append("url_slug", "gsharshop");
    axios.post(process.env.REACT_APP_BASE_URL, bodyFormData).then((res) => {
      console.log(res.data);
      setStoreMenu(res.data.storeData.links);
      setStoreName(res.data.storeData.store_name);
    });
  }, []);

  const noOfQuantity = () => {
    let cart = JSON.parse(localStorage.getItem("products"));
    var total = 0;
    cart?.map((product) => {
      total = product.qty + total;
    });
    return total;
  };

  return (
    <>
      <header
        id="header"
        className={cx(true)}
        style={{
          backgroundColor: (isSticky || productDetail) && "#000000b3",
          // top: globalConfig.isStorePage
          //   ? 0
          //   : width < 449
          //   ? 63
          //   : width < 576
          //   ? 68
          //   : 90,
          top: 0,
          padding: productDetail ? "0px" : "15px 0px",
        }}
      >
        <Container fluid="xl">
          <div className="d-flex">
            <Link to={`/stores/gsharshop`}>
              <span style={{ fontWeight: "bold", fontSize: 19, color: "#fff" }}>
                <img
                  style={{
                    transition: "0.5s all ease",
                    width: isSticky ? "100px" : "130px",
                    filter: "brightness(0) invert(1)",
                  }}
                  // src="https://bo.discovershurooq.ae/files/Untitled_design__4_-removebg-preview6267a7343af6c.png"
                  src={storeLogo}
                ></img>
              </span>
            </Link>
            <div
              className="align-items-center "
              style={{ marginLeft: "50px",display:"flex" }}
            >
              {/* <img src={clock} alt="" />
              <span
                style={{
                  marginLeft: "15px",
                  fontWeight: 500,
                  fontSize: 15,
                  color: "#fff",
                }}
              >
                10:00 - 20:00
              </span> */}
              <a
                style={{
                  marginLeft: "20px",
                }}
                // className="custom-btn custom-btn-xxs custom-btn-circle custom-btn-light font-size-xxxs text-gray-350"
                href="https://www.instagram.com/gsharshop/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  style={{
                    color: "white",
                    fontSize: "22px",
                  }}
                  className="fab fa-instagram"
                ></i>
              </a>
              <a
                style={{
                  marginLeft: "20px",
                }}
                // className="custom-btn custom-btn-xxs custom-btn-circle custom-btn-light font-size-xxxs text-gray-350"
                href="https://api.whatsapp.com/send/?phone=971564005045&lang=ar"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  style={{
                    color: "white",
                    fontSize: "22px",
                  }}
                  className="fab fa-whatsapp"
                ></i>
              </a>
            </div>
          </div>

          <Menus
            active={menu}
            close={() => setMenu(false)}
            menuData={storeMenu}
          />
          <div className="d-flex">
            <Link
              to={`/stores/gsharshop/cart`}
              style={{ fontSize: "20px", color: "White" }}
            >
              {"    "}
              {/* <i
              className="fa fa-shopping-cart"
              aria-hidden="true"
              style={{ color: "White" }}
            ></i> */}
              {/* <img src={shoppingBag} />{" "} */}
              {/* <svg
                className="w-3 h-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  height: "2rem",
                }}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                ></path>
              </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-cart4"
                viewBox="0 0 16 16"
              >
                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
              </svg>
              <span class="badge badge-warning" id="lblCartCount">
                {noOfQuantity()}
              </span>
            </Link>
            <Link to={storeData.user.token ? "/orders-settings" : "/login"}>
              <div
                className="align-items-center bannerclocktime cursor-pointer"
                style={{ marginLeft: "50px", marginTop: "5px" }}
              >
                <img src={login} alt="" />
                <span
                  style={{
                    marginLeft: "15px",
                    fontWeight: 500,
                    fontSize: 15,
                    color: "#fff",
                  }}
                >
                  {storeData.user.token ? "My Account" : "Log In"}
                </span>
              </div>
            </Link>
            <button
              onClick={() => setMenu(true)}
              className="menu-btn d-lg-none"
            >
              <MenuIcon />
            </button>
          </div>
        </Container>
      </header>
    </>
  );
}
export default StoreMenu;
