import React, { useEffect } from "react";
const Vouchers = (props) => {
    useEffect(() => {
        window.location.href = '/vouchers/location';
    }, [])
    return (
        <>
        </>
    );
};
export default Vouchers;