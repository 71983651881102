import { Container, Modal } from "reactstrap";
import bannerImage from "../../../assets/images/backgrounds/h-banner.png";

import bannerdesktopImage1 from "../../../assets/images/h-banner-desktop/ban-desktop-image-1.jpg";
import bannerdesktopImage2 from "../../../assets/images/h-banner-desktop/ban-desktop-image-2.jpg";
import bannerdesktopImage3 from "../../../assets/images/h-banner-desktop/ban-desktop-image-3.jpg";
import bannerdesktopImage4 from "../../../assets/images/h-banner-desktop/ban-desktop-image-4.jpg";
import bannerdesktopImage5 from "../../../assets/images/h-banner-desktop/ban-desktop-image-5.jpg";
import bannerdesktopImage6 from "../../../assets/images/h-banner-desktop/ban-desktop-image-6.jpg";
import bannerdesktopImage7 from "../../../assets/images/h-banner-desktop/ban-desktop-image-7.jpg";
import bannerdesktopImage8 from "../../../assets/images/h-banner-desktop/ban-desktop-image-8.jpg";
import bannerdesktopImage9 from "../../../assets/images/h-banner-desktop/ban-desktop-image-9.jpg";
import bannerdesktopImage10 from "../../../assets/images/h-banner-desktop/ban-desktop-image-10.jpg";
import bannerdesktopImage11 from "../../../assets/images/h-banner-desktop/ban-desktop-image-11.jpg";

import bannermobileImage1 from "../../../assets/images/h-banner-mobile/ban-mobile-image-1.jpg";
import bannermobileImage2 from "../../../assets/images/h-banner-mobile/ban-mobile-image-2.jpg";
import bannermobileImage3 from "../../../assets/images/h-banner-mobile/ban-mobile-image-3.jpg";
import bannermobileImage4 from "../../../assets/images/h-banner-mobile/ban-mobile-image-4.jpg";
import bannermobileImage5 from "../../../assets/images/h-banner-mobile/ban-mobile-image-5.jpg";
import bannermobileImage6 from "../../../assets/images/h-banner-mobile/ban-mobile-image-6.jpg";
import bannermobileImage7 from "../../../assets/images/h-banner-mobile/ban-mobile-image-7.jpg";
import bannermobileImage8 from "../../../assets/images/h-banner-mobile/ban-mobile-image-8.jpg";
import bannermobileImage9 from "../../../assets/images/h-banner-mobile/ban-mobile-image-9.jpg";
import bannermobileImage10 from "../../../assets/images/h-banner-mobile/ban-mobile-image-10.jpg";
import bannermobileImage11 from "../../../assets/images/h-banner-mobile/ban-mobile-image-11.jpg";
import bannermobileImage12 from "../../../assets/images/h-banner-mobile/ban-mobile-image-12.jpg";


import bottomContentData from "./bottomContentData";
import SearchDestination from "../../SearchDestination";
import CaretRight from "../../../assets/images/icons/CaretRight.svg";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import AdPopUp from "../../../assets/images/ad-popup.png";
import "./homebanner.scss";
import OwlCarousel from "react-owl-carousel2";

const options = {
  nav: true,
  loop: true,
  autoplay: true,
  autoplayTimeout: 10000,

  lazyLoad: true,
  animateOut: "fadeOut",

  dots: true,
  nav: false,
  items: 1,
};

function BottomContent() {
  return (
    <div className="b-bottom-content">
      {bottomContentData.map(({ icon, title, link }, index) => (
        <div key={index} className="content">
          <Link to={link}>
            <div className="item">
              <img src={icon} className="icon" />
              <p className="b-title">{title}</p>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}

const HomeBanner = (props) => {
  const { data, homeData } = props;
  const [showAd, setShowAd] = useState(() => false);
  const history = useHistory();
  const openAd = () => setShowAd(true);
  const closeAd = () => setShowAd(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [bannerImages, setBannerImages] = useState([]);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    if (width < 767) {
      setBannerImages([
        bannermobileImage1,
        bannermobileImage2,
        bannermobileImage3,
        bannermobileImage4,
        bannermobileImage5,
        bannermobileImage6,
        bannermobileImage7,
        bannermobileImage8,
        bannermobileImage9,
        bannermobileImage10,
        bannermobileImage11,
        bannermobileImage12,
      ]);
    } else {
      setBannerImages([
        bannerdesktopImage1,
        bannerdesktopImage2,
        bannerdesktopImage3,
        bannerdesktopImage4,
        bannerdesktopImage5,
        bannerdesktopImage6,
        bannerdesktopImage7,
        bannerdesktopImage8,
        bannerdesktopImage9,
        bannerdesktopImage10,
        bannerdesktopImage11,
      ]);
    }
  }, [width]);

  return (
    <div className="home-banner">
      {bannerImages.length > 0 && (
        <OwlCarousel options={options}>
          {bannerImages.map((banImage) => (
            <section
              className="h-banner"
              style={{
                // backgroundImage: `url(${
                //   homeData?.main_hero_bg_image == ""
                //     ? bannerImage
                //     : `http://api.discovershurooq.ae/files/` +
                //       homeData?.main_hero_bg_image
                // })`,
                backgroundImage: `url(${banImage})`,
              }}
            >
              <Container></Container>
            </section>
          ))}
        </OwlCarousel>
      )}
      <div className="h-content">
        <div />
        <div className="bannerText">
          <h2>
            Discover the best of Sharjah’s sights, activities, culture and more
          </h2>
          {/* <span className="scrollText">scroll down</span> */}
          {/* <SearchDestination data={data} /> */}
        </div>
        {/* <BottomContent /> */}
      </div>
      <div
        className="container-fluid py-2 text-light text-center ad"
        style={{ backgroundColor: "black", cursor: "pointer", color: "white" }}
        onClick={openAd}
      >
        <div className="promotion-welcome h3">
          Get 10% off your first order with promo{" "}
          <span className="bg-warning px-2">WELCOME10</span>
        </div>
      </div>
      <div
        className="container-fluid py-2 text-light text-center ad d-none"
        style={{
          backgroundColor: "lightblue",
          cursor: "pointer",
          color: "white",
        }}
        onClick={() => history.push("/upload")}
      >
        <div className="promotion-welcome">
          Spend AED 50 and stand a chance to win up to
          <span className="bg-warning px-2" style={{ color: "#eb6139" }}>
            AED15000
          </span>
        </div>
      </div>
      <Modal
        isOpen={showAd}
        centered
        keyboard
        backdrop={false}
        size="lg"
        scrollable={false}
        toggle={() => setShowAd(!showAd)}
        onOpened={() => {
          document.body.style.overflow = "hidden";
        }}
        onClosed={() => {
          document.body.style.overflow = "auto";
        }}
      >
        <div
          className="ad-modal text-center text-uppercase d-flex flex-column align-items-center position-relative"
          style={{ gap: "15px" }}
        >
          <div className="close-icon">
            <button
              type="button"
              className="close"
              onClick={closeAd}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <img src={AdPopUp} alt="dummy" className="w-100" />
          {/* <div style={{ maxWidth: 300 }}>
 
						<h4 className="font-weight-bold">
							Your special discount is waiting
						</h4>
					</div>
					<img
						src="https://dummyimage.com/250x250/b0b0b0/fff.jpg"
						alt="dummy"
						style={{ width: 250, height: 250, objectFit: "cover" }}
					/>
					<div
						className="d-flex flex-column align-items-center"
						style={{ maxWidth: 250 }}
					>
						<h4 className="font-weight-bold">
							Your first order with promo
						</h4>
						<span className="text-uppercase h4 bg-warning px-2 py-1 text-white">
							WELCOME10
						</span>
					</div> */}
        </div>
      </Modal>
    </div>
  );
};

export default HomeBanner;
