import React from "react";
import styled from "styled-components";
import Sucess from "../../assets/images/icons/sucess.svg";
import './ordersucess.scss'
function OrderSucess() {
  
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100vh",
        backgroundColor: "white",
        zIndex: "9999",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={Sucess} width={160} height={160} className="mb-4" />
      <h2 style={{ fontSize: "20px", fontWeight: "500", textAlign: "center" }}>
        Your order is successful!
      </h2>
      <Dec>
        You will receive a confirmation message shortly. For more details, check
        order status on your WhatsApp.
      </Dec>
      <div className="d-flex gap-4 flex-column flex-md-row">
          <button className="shopping-btn">
                          Track Order</button>
          <button className="shopping-btn trackorder-btn ">
                           Continue Shopping</button>
      </div>
    </div>
  );
}

export default OrderSucess;

const Dec = styled.p`
font-size: 15px;
    font-weight: 400;
    text-align: center;
    color: grey;
    width: 30%;
    line-height: 25px;
    @media (max-width:767px){
        width:90%
    }
`