import { useEffect, useMemo, useState, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

export default function ProductPagination(props) {
  const { total, currentPage, onPageChange } = props;
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setTotalPages(Math.ceil(total / 20));
  }, [total]);

  const paginationData = useMemo(() => {
    const Pages = [];
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(startPage + 3, totalPages);

    if (startPage > 1) {
      Pages.push(
        <PaginationItem>
          <PaginationLink
            onClick={() => onPageChange(1)}
            style={{
              color: "rgb(248, 152, 40)",
              borderColor: "rgb(248, 152, 40)",
            }}
          >
            1
          </PaginationLink>
        </PaginationItem>
      );
      if (startPage > 2) {
        Pages.push(
          <PaginationItem disabled>
            <PaginationLink
              style={{
                color: "rgb(248, 152, 40)",
                borderColor: "rgb(248, 152, 40)",
              }}
            >
              ...
            </PaginationLink>
          </PaginationItem>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      Pages.push(
        <PaginationItem active={i === currentPage}>
          <PaginationLink
            style={{
              backgroundColor: i === currentPage ? "rgb(248, 152, 40)" : "",
              color: i === currentPage ? "white" : "rgb(248, 152, 40)",
              borderColor: "rgb(248, 152, 40)",
            }}
            onClick={() => onPageChange(i)}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        Pages.push(
          <PaginationItem disabled>
            <PaginationLink
              style={{
                color: "rgb(248, 152, 40)",
                borderColor: "rgb(248, 152, 40)",
              }}
            >
              ...
            </PaginationLink>
          </PaginationItem>
        );
      }
      Pages.push(
        <PaginationItem>
          <PaginationLink
            onClick={() => onPageChange(totalPages)}
            style={{
              color: "rgb(248, 152, 40)",
              borderColor: "rgb(248, 152, 40)",
            }}
          >
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return Pages;
  }, [totalPages, currentPage]);

  const handlePageChange = useCallback(
    (page) => {
      onPageChange(page);
    },
    [onPageChange]
  );

  if (!totalPages || totalPages === 1) {
    return null;
  }

  return (
    <div>
      <Pagination>
        <PaginationItem>
          <PaginationLink
            style={{
              color: "rgb(248, 152, 40)",
              borderColor: "rgb(248, 152, 40)",
            }}
            previous
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
        </PaginationItem>
        {paginationData}
        <PaginationItem>
          <PaginationLink
            style={{
              color: "rgb(248, 152, 40)",
              borderColor: "rgb(248, 152, 40)",
            }}
            next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </PaginationItem>
      </Pagination>
    </div>
  );
}
