import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import filterImage from "../../../src/assets/images/list-settings.png";
import "./style.scss";
import { useLocation } from "react-router-dom";

export default function Filters({
  products,
  setProducts,
  tempProducts,
  collectionsList,
  showCollectionFilters,
}) {
  const [search, setSearch] = useState("");
  const [sortType, setSortType] = useState("LTH");
  const [selectedCollection, setSelectedCollection] = useState({
    collection_name: "All",
  });
  const location = useLocation();
  const filters = [
    {
      title: "Sort By",
      subFilters: [
        { filterId: "LTH", title: "Price: Low to High" },
        { filterId: "HTL", title: "Price: High to Low" },
        { filterId: "NWA", title: "Newest Arrivals" },
      ],
    },
  ];

  useEffect(() => {
    if (location.state?.searchInput) {
      const searchValue = location.state.searchInput;
      setSearch(searchValue);
      filterItems(searchValue, sortType, selectedCollection.collection_name);
    }
  }, [tempProducts]);

  const handleFilterChange = () => {
    document.getElementById("filter-dropdown").classList.toggle("show");
  };
  const handleCollectionChange = () => {
    document.getElementById("collection-dropdown").classList.toggle("show");
  };

  window.onclick = function (event) {
    if (!event.target.matches(".filter-item")) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      for (var i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains("show")) {
          openDropdown.classList.remove("show");
        }
      }
    }
  };

  const filterItems = (searchValue, sort, selectValue) => {
    if (selectValue === "All") {
      var filteredProducts = tempProducts;
    } else {
      var filteredProducts = [];
      tempProducts.map((product) => {
        JSON.parse(product.collections).filter((collection) => {
          if (collection === selectValue) {
            filteredProducts.push(product);
          }
        });
      });
    }

    filteredProducts = filteredProducts.filter(
      (tempProduct) =>
        tempProduct.product_title
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1
    );

    filteredProducts = filteredProducts
      .sort((a, b) => {
        if (sort === "LTH") {
          return Number(a.price) - Number(b.price);
        } else if (sort === "HTL") {
          return Number(b.price) - Number(a.price);
        } else {
          return new Date(a.created_at) - new Date(b.created_at);
        }
      })
      .map((product) => product);
    setProducts(filteredProducts);
  };

  return (
    <>
      {" "}
      <div className="filter-container">
        <input
          value={search}
          placeholder="search"
          onChange={(e) => {
            setSearch(e.target.value);
            filterItems(
              e.target.value,
              sortType,
              selectedCollection.collection_name
            );
          }}
          className="search-input"
        />
        {showCollectionFilters && (
          <div className="dropdown" style={{ marginTop: "0.5rem" }}>
            <button className="filter-item" onClick={handleCollectionChange}>
              <img src={filterImage} style={{ marginRight: "10px" }} />{" "}
              {selectedCollection.collection_name}
            </button>
            <div id="collection-dropdown" className="dropdown-content">
              <div
                className="sub-filter"
                onClick={() => {
                  setSelectedCollection({ collection_name: "All" });
                  filterItems(search, sortType, "All");
                }}
              >
                All
              </div>
              {collectionsList.map((collection) => (
                <div
                  className="sub-filter"
                  onClick={() => {
                    setSelectedCollection(collection);
                    filterItems(search, sortType, collection.id);
                  }}
                >
                  {collection.collection_name}
                </div>
              ))}
            </div>
          </div>
        )}
        {/* {filters.map((filter) => (
          <div className="button-dropdown">
            <button className="filter-item" onClick={handleFilterChange}>
              {filter.title}
            </button>
            <div id="filter-dropdown" className="dropdown-content">
              {filter.subFilters.map((subFilter) => (
                <div
                  className="sub-filter"
                  onClick={() => {
                    setSortType(subFilter.filterId);
                    filterItems(
                      search,
                      subFilter.filterId,
                      selectedCollection.collection_name
                    );
                  }}
                >
                  {subFilter.title}
                </div>
              ))}
            </div>
          </div>
        ))} */}
      </div>
    </>
  );
}
